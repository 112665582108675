const nType =  {
    getDatas: 'GETHONGS',
    getData: 'GETHONG',
    add: 'ADDHONG',
    delete: 'DELETEHONG',
    export: 'EXPORTHONG',
    copy: 'COPYHONG',
    // rest: 'RESRHONG',
    edit: 'EDITHONG',
    save: 'SAVEHONG',
    honginstall:'HONINSTALL',
    addhongname: 'ADDHONGNAME',
    Import: 'HONGIMPORT',
};
export default nType;