import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FileAddFilled, UploadOutlined } from '@ant-design/icons'
import {
  add,
  deleteone,
  edit,
  copy,
  exportFile,
  doImportSetting
} from '../../store/hong/imp.ts';
import ItemBtn from '../../common/component/cItem/Index.tsx'
import { Modal } from 'antd';


const option = ['export', 'copy', 'edit','delete'];
const HONG = (props:any) => {
  const hongSetting = useSelector((state:any) => {
    return state.hong.settings;
  });
  const dispatch = useDispatch();
  let [showInput, setShowInput] = useState<string>("");

  const handleClick = (type:string, key?:string, value?:string) => {
    switch(type) {
      case 'add': dispatch(add());break;
      case 'copy': if (key) dispatch(copy(key));break;
      case 'edit': if (key && value) dispatch(edit(key, value));break;  
      case 'delete': 
      const name = hongSetting.find((item) => item?.key === key)?.name;
      if (key)
        Modal.confirm({
          title: `${props?.t('sureDelete')}${name}?`,
          onOk() {
            dispatch(deleteone(key));
          },
          okText: `${props?.t('sure')}`,
          cancelText: `${props?.t('cancel')}`
        });
      break;
      case 'export': if (key) {
        const item = hongSetting.find((ele:any) => ele.key === key);
        exportFile(JSON.stringify(item));
      };break;
      case 'click': if (key && props?.setChecked) props?.setChecked(key) ;break;
    } 
  }

  const InputFile = (e:any) => {
    const file = e.target.files[0];
    if (!file) {
        return;
    }
    const reader = new FileReader();
    reader.onload = function(e:any) {
        const content = e?.target?.result;
        if (content && JSON.parse(content)) {
          dispatch(doImportSetting(content));
        } else {
          alert('import fail');
        }
       
        // 这里可以处理读取到的文本内容
    };
    reader.onerror = function(e:any) {
        console.error("读取文件时发生错误:", e.target.error);
    };
    reader.readAsText(file, 'UTF-8'); // 默认编码格式为UTF-8，可以根据需要更改
    e.target.value = null;
  }

  return (
    <div className='_slider_hong'>
        <div className='_slider_header_text'>
           <span>{`${props.t("hong-name")}`}</span>
        </div>
        <div className='_slider_header_svg'>
          <FileAddFilled onClick={() => handleClick('add')} />
          <UploadOutlined onClick={() => document?.getElementById('h-file')?.click()}/>
          <input id='h-file' type='file' accept='text' style={{display:'none'}} onChange={InputFile}/>
        </div>
        <div className={`_slider_body`}>
          {hongSetting ? hongSetting.map((item:any) => {
              return ItemBtn(
                item,
                (type:string, value?:string)=> {
                  handleClick(type, item?.key, value);
                },
                props.t,
                {
                  showInput,
                  setShowInput
                }, option,
                props.checkHong === item.key);
            }):null}
        </div>
    </div>
  );
}

export default HONG;