
import {funFormat} from '../common/request/format.ts'
// 设置定时器标识
let timer:any = {};
export function debounce(tg:string, func:Function, delay:number=500) {
  // 难点返回事件绑定函数
  clearTimeout(timer[tg]);
    //设置定时器
  timer[tg] = setTimeout(() => {
    // 调用函数
    func();
  }, delay);
}

// 计算校验长度
export function calcData(arr:Array<string> = []):string[] {
  if (!arr || !arr?.length)  return ['0x00','0x00'];
  let num:number = 0;
  arr.forEach((ele)=> {num += parseInt(ele, 16)});
  
  const str:string = `0000${num.toString(16)}`;
  return [
    str.substring(str.length-2, str.length),
    str.substring(str.length-4, str.length-2)
  ]
}
// 处理颜色
export function clacColor(str:string):string {
  if (!str) return "000000";
  let newstr:string = "000000";
  for(let i = 0; i < str?.length; i++) {
    if (str[i] && str[i] !== '#') newstr += `${str[i]}`;
  }
  return `#${newstr.substring(newstr.length-6, newstr.length)}`;
}

// 计算时间
export function calcTime(num:number):string[] {
  if (!num) return ['0x00','0x00'];
  const str:string = `0000${num.toString(16)}`;
  return [
    str.substring(str.length-2, str.length),
    str.substring(str.length-4, str.length-2)
  ]
}


// 计算时间
export function tenTo16(num:number):string {
  if (!num) return '0x00';
  const str:string = `00${num.toString(16)}`;
  return `0x${str.substring(str.length-2, str.length)}`;
}

export function exportHongJSON(jsonData:string, filename:string) {
  const jsonString:any = jsonData;
  const blob = new Blob([jsonString], {type: 'application/json'});
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url);
}

export function exportJSON(jsonData:any, filename:string) {
  const jsonString:any = JSON.stringify(N16ToJson(JSON.parse(jsonData)));
  const blob = new Blob([jsonString], {type: 'application/json'});
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url);
}


//读取文件
export const readFile = (str:string) => {
  if (!str) return '';
  try {
    const json:any = JSON.parse(str);
    let tysetting:any = [];
    for (let i = 0; i < 64; i++) tysetting.push('0x00');
    if (json?.LightInfo && json?.SystemInfo) {
      const arr = funFormat.map((key) => json?.LightInfo?.[key] || json?.SystemInfo?.[key] || '0x00');
      tysetting = tysetting.map((item:any, i:number) => arr[i] || item);
    }
    let default_key_Rect:any = [];
    let key_Rect:any = []; 
    if (json?.KeySetInfo && json?.KeySetInfo?.DeviceKeyDataInfo &&
      json?.KeySetInfo?.DeviceKeyDataInfo?.length
    ) {
      for(let {KeyData, DefaultKeyData} of json?.KeySetInfo?.DeviceKeyDataInfo) {
        default_key_Rect.push(DefaultKeyData?.KeyType);
        default_key_Rect.push(DefaultKeyData?.KeyCodel);
        default_key_Rect.push(DefaultKeyData?.KeyCodeh);
        key_Rect.push(KeyData?.KeyType);
        key_Rect.push(KeyData?.KeyCodel);
        key_Rect.push(KeyData?.KeyCodeh);
      }
    }
    let light_rect:any = [];
    if (json?.CustomModeColorData) {
      for(let item of json?.CustomModeColorData) {
        light_rect.push(item?.byLightColorR);
        light_rect.push(item?.byLightColorG);
        light_rect.push(item?.byLightColorB);
      }
    }
    return {
      default_key_Rect,
      key_Rect,
      tysetting,
      name: `import${json?.name ? json?.name : '_now'}`,
    }
  } catch (e) {
    alert('fommat error');
    return '';
  }
}

//下载文件格式转换

export const N16ToJson = ({tysetting, key_Rect, default_key_Rect, light_rect, name}:any) => {
  let LightInfo:any = {}; // 灯光信息格式转换
  let SystemInfo:any = {}; // 系统信息格式转换
  let DeviceKeyDataInfo:any = []; //按键的当前值和默认值 
  let CustomModeColorData:any = []; //客户自定义灯光

  funFormat.forEach((key:string, i:number) => {
    if (i>0 && i < 10) {
      LightInfo[key] = tysetting?.[i];
    } else if (i > 11) {
      SystemInfo[key] = tysetting?.[i];
    }
    
  });
  for(let i = 0; i < Math.ceil(default_key_Rect?.length/3); i++) {
    if (key_Rect[i*3] ||key_Rect[i*3+1] || key_Rect[i*3+2]) {
      DeviceKeyDataInfo.push({
        "KeyData": {
          "KeyIndex": i,
          "KeyType": key_Rect[i*3],
          "KeyCodel": key_Rect[i*3+1],
          "KeyCodeh": key_Rect[i*3+2],
        },
        "DefaultKeyData": {
            "KeyIndex": i,
            "KeyType": default_key_Rect[i*3],
            "KeyCodel": default_key_Rect[i*3+1],
            "KeyCodeh": default_key_Rect[i*3+2],
        }
      });
    }
  }
  if (light_rect && light_rect?.length) {
    for(let i = 0; i < Math.ceil(light_rect?.length/3); i++) {
      if (light_rect[i*3] ||light_rect[i*3+1] || light_rect[i*3+2]) {
        CustomModeColorData.push({
          "byLightColorR": [i*3],
          "byLightColorG": [i*3+1],
          "byLightColorB": [i*3+2],
          "byLightAlpha": 1
        });
      }
    }
  }

  return {
    KeySetInfo: {
      DeviceKeyDataInfo
    },
    LightInfo,
    SystemInfo,
    CustomModeColorData,
    name
  }
}