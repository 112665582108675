const allkeys:any = {
    Escape: {
        id: 1,
        key: ['Esc'],
        keyCode: 27,
        code: 'Escape',
    },
    F1: {
        id: 2,
        key: ['F1'],
        keyCode: 112,
        code: 'F1',
    },
    F2: {
        id: 3,
        key: ['F2'],
        keyCode: 113,
        code: 'F2',
    },
    F3: {
        id: 4,
        key: ['F3'],
        keyCode: 114,
        code: 'F3',
    },
    F4: {
        id: 5,
        key: ['F4'],
        keyCode: 115,
        code: 'F4',
    },
    F5: {
        id: 6,
        key: ['F5'],
        keyCode: 116,
        code: 'F5',
    },
    F6: {
        id: 7,
        key: ['F6'],
        keyCode: 117,
        code: 'F6',
    },
    F7: {
        id: 8,
        key: ['F7'],
        keyCode: 118,
        code: 'F7',
    },
    F8: {
        id: 9,
        key: ['F8'],
        keyCode: 119,
        code: 'F8',
    },
    F9: {
        id: 10,
        key: ['F9'],
        keyCode: 120,
        code: 'F9',
    },
    F10: {
        id: 11,
        key: ['F10'],
        keyCode: 121,
        code: 'F10',
    },
    F11: {
        id: 12,
        key: ['F11'],
        keyCode: 122,
        code: 'F11',
    },
    F12: {
        id: 13,
        key: ['F12'],
        keyCode: 123,
        code: 'F12',
    },
    PrintScreen: {
        id: 14,
        key: ['Print'],
        keyCode: 154,
        code: 'PrintScreen',
    },
    ScrollLock: {   
        id: 15,
        key: ['Scroll'],
        keyCode: 145,
        code: 'ScrollLock',
    }, 
    Pause: {
        id: 16,
        key: ['Pause'],
        keyCode: 35,
        code: 'Pause',
    },
    VOLADD: {
        id: 17,
        key: ['VOL+'],
        keyCode: 33,
        code: 'VOL_ADD',
    },
    VOLDEC: {
        id: 18,
        key: ['VOL-'],
        keyCode: 34,
        code: 'VOL_DEC',
    },
    Mute: {
        id: 19,
        key: ['MUTE'],
        keyCode: 33,
        code: 'Mute',
    },
    Calc: {
        id: 20,
        key: ['CALC'],
        keyCode: 34,
        code: 'Calc',
    },
    Backquote: {
        id: 21,
        key: ['`', '~'],
        keyCode: 192,
        code: 'Backquote',
    },
    Digit1: {
        id: 22,
        key: ['1', '!'],
        keyCode: 49,
        code: 'Digit1',
    },
    Digit2: {
        id: 23,
        key: ['2','@'],
        keyCode: 50,
        code: 'Digit2',
    },
    Digit3: {
        id: 24,
        key: ['3','#'],
        keyCode: 51,
        code: 'Digit3',
    },
    Digit4: {
        id: 25,
        key: ['4', '$'],
        keyCode: 52,
        code: 'Digit4',
    },
    Digit5: {
        id: 26,
        key: ['5', '%'],
        keyCode: 53,
        code: 'Digit5',
    },
    Digit6: {
        id: 27,
        key: ['6', '^'],
        keyCode: 54,
        code: 'Digit6',
    },
    Digit7: {
        id: 28,
        key: ['7', '&'],
        keyCode: 55,
        code: 'Digit7',
    },
    Digit8: {
        id: 29,
        key: ['8','*'],
        keyCode: 56,
        code: 'Digit8',
    },
    Digit9: {
        id: 30,
        key: ['9','('],
        keyCode: 57,
        code: 'Digit9',
    },
    Digit0: {
        id: 31,
        key: ['0',')'],
        keyCode: 48,
        code: 'Digit0',
    },
    Minus: {
        id: 32,
        key: ['-','_'],
        keyCode: 189,
        code: 'Minus',
    },
    Equal: {
        id: 33,
        key: ['=','+'],
        keyCode: 187,
        code: 'Equal',
    },
    Backspace: {
        id: 34,
        key: ['Backspace'],
        keyCode: 8,
        code: 'Backspace',
    },
    Insert: {
        id: 35,
        key: ['Insert'],
        keyCode: 45,
        code: 'Insert',
    },
    Home: {
        id: 36,
        key: ['Home'],
        keyCode: 36,
        code: 'Home',
    },
    PageUp: {
        id: 37,
        key: ['PgUp'],
        keyCode: 33,
        code: 'PageUp',
    },
    NumLock: {
        id: 38,
        key: ['NUM'],
        code: 'NumLock',
        keyCode: 144,
    },
    NumpadDivide: {
        id: 39,
        key: ['/'],
        keyCode: 111,
        code: 'NumpadDivide',
    },
    NumpadMultiply: {
        id: 40,
        key: ['*'],
        keyCode: 106,
        code: 'NumpadMultiply',
    },
    NumpadSubtract: {
        id: 41,
        key: ['-'],
        keyCode: 109,
        code: 'NumpadSubtract',
    },
    Tab: {
        id: 42,
        key: ['TAB'],
        keyCode: 9,
        code: 'Tab',
    },
    KeyQ: {
        id: 43,
        key: ['Q'],
        keyCode: 81,
        code: 'KeyQ',
    },
    KeyW: {
        id: 44,
        key: ['W'],
        keyCode: 87,
        code: 'KeyW',
    },
    KeyE: {
        id: 45,
        key: ['E'],
        keyCode: 69,
        code: 'KeyE',
    },
    KeyR: {
        id: 46,
        key: ['R'],
        keyCode: 82,
        code: 'KeyR',
    },
    KeyT: {
        id: 47,
        key: ['T'],
        keyCode: 84,
        code: 'KeyT',
    },
    KeyY: {
        id: 48,
        key: ['Y'],
        keyCode: 89,
        code: 'KeyY',
    },
    KeyU: {
        id: 49,
        key: ['U'],
        keyCode: 85,
        code: 'KeyU',
    },
    KeyI: {
        id: 50,
        key: ['I'],
        keyCode: 73,
        code: 'KeyI',
    },
    KeyO: {
        id: 51,
        key: ['O'],
        keyCode: 79,
        code: 'KeyO',
    },
    KeyP:{
        id: 52,
        key: ['P'],
        keyCode: 80,
        code: 'KeyP',
    },
    BracketLeft: {
        id: 53,
        key: ['[','{'],
        keyCode: 219,
        code: 'BracketLeft',
    },
    BracketRight: {
        id: 54,
        key: [']', '}'],
        keyCode: 221,
        code: 'BracketRight',
    },
    Backslash: {
        id: 55,
        key: ['\\', '|'],
        keyCode: 220,
        code: 'Backslash',
    },
    Delete: {
        id: 56,
        key: ['Del'],
        keyCode: 46,
        code: 'Delete',
    },
    End: {
        id: 57,
        key: ['End'],
        keyCode: 35,
        code: 'End',
    },
    PageDown: {
        id: 58,
        key: ['PgDn'],
        keyCode: 34,
        code: 'PageDown',
    },
    Numpad7: {
        id: 59,
        key: ['7'],
        keyCode: 103,
        code: 'Numpad7',
    },
    Numpad8: {
        id: 60,
        key: ['8'],
        keyCode: 104,
        code: 'Numpad8',
    },
    Numpad9: {
        id: 61,
        key: ['9'],
        keyCode: 105,
        code: 'Numpad9',
    },
    NumpadAdd: {
        id: 62,
        key: ['+'],
        keyCode: 107,
        code: 'NumpadAdd',
    },
    CapsLock: {
        id: 63,
        key: ['CAPSLK'],
        keyCode: 20,
        code: 'CapsLock',
    },
    KeyA: {
        id: 64,
        key: ['A'],
        keyCode: 65,
        code: 'KeyA',
    },
    KeyS: {
        id: 65,
        key: ['S'],
        keyCode: 83,
        code: 'KeyS',
    },
    KeyD: {
        id: 66,
        key: ['D'],
        keyCode: 68,
        code: 'KeyD',
    },
    KeyF: {
        id: 67,
        key: ['F'],
        keyCode: 70,
        code: 'KeyF',
    },
    KeyG: {
        id: 68,
        key: ['G'],
        keyCode: 71,
        code: 'KeyG',
    },
    KeyH: {
        id: 69,
        key: ['H'],
        keyCode: 72,
        code: 'KeyH',
    },
    KeyJ: {
        id: 70,
        key: ['J'],
        keyCode: 74,
        code: 'KeyJ',
    },
    KeyK: {
        id: 71,
        key: ['K'],
        keyCode: 75,
        code: 'KeyK',
    },
    KeyL: {
        id: 72,
        key: ['L'],
        keyCode: 76,
        code: 'KeyL',
    },
    Semicolon: {
        id: 73,
        key: [';',':'],
        keyCode: 186,
        code: 'Semicolon',
    },
    Quote: {
        id: 74,
        key: ['\'','"'],
        keyCode: 220,
        code: 'Quote',
    },
    Enter: {
        id: 75,
        key: ['ENTER'],
        keyCode: 13,
        code: 'Enter',
    },
    Numpad4: {
        id: 77,
        key: ['4'],
        keyCode: 100,
        code: 'Numpad4',
    },
    Numpad5: {
        id: 78,
        key: ['5'],
        keyCode: 101,
        code: 'Numpad5',
    },
    Numpad6: {
        id: 79,
        key: ['6'],
        keyCode: 102,
        code: 'Numpad6',
    },
    ShiftLeft: {
        id: 76,
        key: ['SHIFT'],
        keyCode: 16,
        code: 'ShiftLeft',
    },
    KeyZ: {
        id: 80,
        key: ['Z'],
        keyCode: 90,
        code: 'KeyZ',
    },
    KeyX: {
        id: 81,
        key: ['X'],
        keyCode: 88,
        code: 'KeyX',
    },
    KeyC: {
        id: 82,
        key: ['C'],
        keyCode: 67,
        code: 'KeyC',
    },
    KeyV: {
        id: 83,
        key: ['V'],
        keyCode: 86,
        code: 'KeyV',
    },
    KeyB: {
        id: 84,
        key: ['B'],
        keyCode: 66,
        code: 'KeyB',
    },
    KeyN: {
        id: 85,
        key: ['N'],
        keyCode: 78,
        code: 'KeyN',
    },
    KeyM: {
        id: 86,
        key: ['M'],
        keyCode: 77,
        code: 'KeyM',
    },
    Comma: {
        id: 87,
        key: [',','<'],
        keyCode: 188,
        code: 'Comma',
    },
    Period: {
        id: 88,
        key: ['.','>'],
        keyCode: 190,
        code: 'Period',
    },
    Slash: {
        id: 89,
        key: ['/','?'],
        keyCode: 191,
        code: 'Slash',
    },
    ShiftRight: {
        id: 90,
        key: ['SHIFT'],
        keyCode: 16,
        code: 'ShiftRight',
    },
    ArrowUp: {
        id: 91,
        key: ['↑'],
        keyCode: 38,
        code: 'ArrowUp',
    },
    Numpad1: {
        id: 92,
        key: ['1'],
        keyCode: 97,
        code: 'Numpad1',
    },
    Numpad2: {
        id: 93,
        key: ['2'],
        keyCode: 98,
        code: 'Numpad2',
    },
    Numpad3: {
        id: 94,
        key: ['3'],
        keyCode: 99,
        code: 'Numpad3',
    },
    ControlLeft: {
        id: 95,
        key: ['CTRL'],
        keyCode: 17,
        code: 'ControlLeft',
    },
    MetaLeft: {
        id: 96,
        key: ['WIN'],
        keyCode: 91,
        code: 'MetaLeft',
    },
    AltLeft: {
        id: 97,
        key: ['ALT'],
        keyCode: 18,
        code: 'AltLeft',
    },
    Space: {
        id: 98,
        key: ['_'],
        keyCode: 32,
        code: 'Space',
    },
    AltRight: {
        id: 99,
        key: ['ALT'],
        code: 'AltRight',
        keyCode: 18,
    },
    WakeUp: {
        id: 100,
        key: ['FN'],
        keyCode: 255,
        code: 'WakeUp',
    },
    App: {
        id: 108,
        key: ['App'],
        keyCode: 255,
        code: 'App',
    },
    ControlRight: {
        id: 101,
        key: ['CTRL'],
        code: 'ControlRight',
        keyCode: 17,
    },
    ArrowLeft: {
        id: 102,
        key: ['←'],
        keyCode: 37,
        code: 'ArrowLeft',
    },
    ArrowDown: {
        id: 103,
        key: ['↓'],
        keyCode: 40,
        code: 'ArrowDown',
    },
    ArrowRight: {
        id: 104,
        key: ['→'],
        keyCode: 39,
        code: 'ArrowRight',
    },
    Numpad0: {
        id: 105,
        key: ['0'],
        keyCode: 96,
        code: 'Numpad0',
    }, 
    NumpadDecimal: {
        id: 106,
        key: ['.'],
        keyCode: 110,
        code: 'NumpadDecimal',
    },
    NumpadEnter: {
        id: 107,
        key: ['ENTER'],
        keyCode: 13,
        code: 'NumpadEnter',
    }, 
};

// 默认按键大小
const keysize = {
    w: 1.5,
    h:1.5,
    nw: 1,
    nh: 1
}

export const keys108 = {
    width: 39.8,
    height: 12.4,
    keys: [
        {
            ...allkeys?.Escape,
            pointer: [1,1],
            message: keysize,

        },
        {
            ...allkeys?.F1, 
            pointer: [4.4,1],
            message: keysize,

        },
        {
            ...allkeys?.F2,
            pointer: [6.1,1],
            message: keysize,
        },
        {
            ...allkeys?.F3,
            pointer: [7.8,1],
            message: keysize,
        },
        {
            ...allkeys?.F4,
            pointer: [9.5,1],
            message: keysize,
        },
        {
            ...allkeys?.F5,
            pointer: [12.05,1],
            message: keysize,
        },
        {
            ...allkeys?.F6,
            pointer: [13.75,1],
            message: keysize,
        },
        {
            ...allkeys?.F7,
            pointer: [15.45,1],
            message: keysize,
        },
        {
            ...allkeys?.F8,
            pointer: [17.15,1],
            message: keysize,
        },
        {
            ...allkeys?.F9,
            pointer: [19.7,1],
            message: keysize,
        },
        {
            ...allkeys?.F10,
            pointer: [21.4,1],
            message: keysize,
        },
        {
            ...allkeys?.F11,
            pointer: [23.1,1],
            message: keysize,
        },
        {
            ...allkeys?.F12,
            pointer: [24.8,1],
            message: keysize,
        },
        {
            ...allkeys?.PrintScreen,
            pointer: [26.8,1],
            message: keysize,
        },
        {   
            ...allkeys?.ScrollLock,
            pointer: [28.5,1],
            message: keysize,
        },
        
        {
            ...allkeys?.Pause,
            pointer: [30.2,1],
            message: keysize,
        },
        {
            ...allkeys?.VOLADD,
            pointer: [32.2, 1],
            message: keysize,
        },
        {
            ...allkeys?.VOLDEC,
            pointer: [33.9,1],
            message: keysize,
        },
        {
            ...allkeys?.Mute,
            pointer: [35.6, 1],
            message: keysize,
        },
        {
            ...allkeys?.Calc,
            pointer: [37.3,1],
            message: keysize,
        },
        {
            ...allkeys?.Backquote,
            pointer: [1, 2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit1,
            pointer: [2.7, 2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit2,
            pointer: [4.4,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit3,
            pointer: [6.1,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit4,
            pointer: [7.8,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit5,
            pointer: [9.5,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit6,
            pointer: [11.2,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit7,
            pointer: [12.9,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit8,
            pointer: [14.6,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit9,
            pointer: [16.3,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit0,
            pointer: [18,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Minus,
            pointer: [19.7,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Equal,
            pointer: [21.4,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Backspace,
            pointer: [23.1, 2.9],
            message: {
                ...keysize,
                w: 3.2,
            }
        },
        {
            ...allkeys?.Insert,
            pointer: [26.8, 2.9],
            message: keysize,
        },
        {
            ...allkeys?.Home,
            pointer: [28.5, 2.9],
            message: keysize,
        },
        {
            ...allkeys?.PageUp,
            pointer: [30.2, 2.9],
            message: keysize,
        },
        {
            ...allkeys?.NumLock,
            pointer: [32.2 ,2.9],
            message: keysize,
        },

        {
            ...allkeys?.NumpadDivide,
            pointer: [33.9,2.9],
            message: keysize,
        },
        {
            ...allkeys?.NumpadMultiply,
            pointer: [35.6,2.9],
            message: keysize,
        },
        {
            ...allkeys?.NumpadSubtract,
            pointer: [37.3,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Tab,
            pointer: [1, 4.6],
            message: {
                ...keysize,
                w: 2,
            }
        },
        {
            ...allkeys?.KeyQ,
            pointer: [3.2, 4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyW,
            pointer: [4.9,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyE,
            pointer: [6.6,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyR,
            pointer: [8.3,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyT,
            pointer: [10,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyY,
            pointer: [11.7,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyU,
            pointer: [13.4,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyI,
            pointer: [15.1,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyO,
            pointer: [16.8,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyP,
            pointer: [18.5,4.6],
            message: keysize,
        },
        {
            ...allkeys?.BracketLeft,
            pointer: [20.2,4.6],
            message: keysize,
        },
        {
            ...allkeys?.BracketRight,
            pointer: [21.9,4.6],
            message: keysize,
        },
        {
            ...allkeys?.Backslash,
            pointer: [23.6,4.6],
            message: {
                ...keysize,
                w: 2.7,
            }
        },
        {
            ...allkeys?.Delete,
            pointer: [26.8, 4.6],
            message: keysize,
        },
        {
            ...allkeys?.End,
            pointer: [28.5, 4.6],
            message: keysize,
        },
        {
            ...allkeys?.PageDown,
            pointer: [30.2, 4.6],
            message: keysize,
        },
        {
            ...allkeys?.Numpad7,
            pointer: [32.2,4.6],
            message: keysize,
        },

        {
            ...allkeys?.Numpad8,
            pointer: [33.9,4.6],
            message: keysize,
        },
        {
            ...allkeys?.Numpad9,
            pointer: [35.6,4.6],
            message: keysize,
        },
        {
            ...allkeys?.NumpadAdd,
            pointer: [37.3,4.6],
            message: {
                ...keysize,
                h: 3.2,
            }
        },
        {
            ...allkeys?.CapsLock,
            pointer: [1, 6.3],
            message: {
                ...keysize,
                w: 2.5,
            }
        },
        {
            ...allkeys?.KeyA,
            pointer: [3.7, 6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyS,
            pointer: [5.4,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyD,
            pointer: [7.1,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyF,
            pointer: [8.8,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyG,
            pointer: [10.5,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyH,
            pointer: [12.2,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyJ,
            pointer: [13.9,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyK,
            pointer: [15.6,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyL,
            pointer: [17.3,6.3],
            message: keysize,
        },
        {
            ...allkeys?.Semicolon,
            pointer: [19,6.3],
            message: keysize,
        },
        {
            ...allkeys?.Quote,
            pointer: [20.7,6.3],
            message: keysize,
        },
        {
            ...allkeys?.Enter,
            pointer: [22.4,6.3],
            message: {
                ...keysize,
                w: 3.9,
            }
        },
        {
            ...allkeys?.Numpad4,
            pointer: [32.2,6.3],
            message: keysize,
        },

        {
            ...allkeys?.Numpad5,
            pointer: [33.9,6.3],
            message: keysize,
        },
        {
            ...allkeys?.Numpad6,
            pointer: [35.6,6.3],
            message: keysize,
        },
        {
            ...allkeys?.ShiftLeft,
            pointer: [1, 8],
            message: {
                ...keysize,
                w: 3.5,
            }
        },
        {
            ...allkeys?.KeyZ,
            pointer: [4.7, 8],
            message: keysize,
        },
        {
            ...allkeys?.KeyX,
            pointer: [6.4,8],
            message: keysize,
        },
        {
            ...allkeys?.KeyC,
            pointer: [8.1,8],
            message: keysize,
        },
        {
            ...allkeys?.KeyV,
            pointer: [9.8,8],
            message: keysize,
        },
        {
            ...allkeys?.KeyB,
            pointer: [11.5,8],
            message: keysize,
        },
        {
            ...allkeys?.KeyN,
            pointer: [13.2,8],
            message: keysize,
        },
        {
            ...allkeys?.KeyM,
            pointer: [14.9,8],
            message: keysize,
        },
        {
            ...allkeys?.Comma,
            pointer: [16.6,8],
            message: keysize,
        },
        {
            ...allkeys?.Period,
            pointer: [18.3,8],
            message: keysize,
        },
        {
            ...allkeys?.Slash,
            pointer: [20,8],
            message: keysize,
        },
        {
            ...allkeys?.ShiftRight,
            pointer: [21.7,8],
            message: {
                ...keysize,
                w: 4.6,
            }
        },
        {
            ...allkeys?.ArrowUp,
            pointer: [28.5,8],
            message: keysize,
        },
        {
            ...allkeys?.Numpad1,
            pointer: [32.2,8],
            message: keysize,
        },

        {
            ...allkeys?.Numpad2,
            pointer: [33.9,8],
            message: keysize,
        },
        {
            ...allkeys?.Numpad3,
            pointer: [35.6,8],
            message: keysize,
        },
        {
            ...allkeys?.ControlLeft,
            pointer: [1,9.7],
            message: {
                ...keysize,
                w: 1.6,
            }
        },
        {
            ...allkeys?.MetaLeft,
            pointer: [2.8,9.7],
            message: {
                ...keysize,
                w: 1.6,
            }
        },
        {
            ...allkeys?.AltLeft,
            pointer: [4.6,9.7],
            message: {
                ...keysize,
                w: 1.6,
            }
        },
        {
            ...allkeys?.Space,
            pointer: [6.4,9.7],
            message: {
                ...keysize,
                w: 12.7,
            }
        },
        {
            ...allkeys?.AltRight,
            pointer: [19.3,9.7],
            message: {
                ...keysize,
                w: 1.6,
            }
        },
        {
            ...allkeys?.WakeUp,
            pointer: [21.1,9.7],
            message: {
                ...keysize,
                w: 1.6,
            }
        },
        {
            ...allkeys?.App,
            pointer: [22.9,9.7],
            message: {
                ...keysize,
                w: 1.6,
            }
        },
        {
            ...allkeys?.ControlRight,
            pointer: [24.7,9.7],
            message: {
                ...keysize,
                w: 1.6,
            }
        },
        
        {
            ...allkeys?.ArrowLeft,
            pointer: [26.7,9.7],
            message: keysize,
        },
        {
            ...allkeys?.ArrowDown,
            pointer: [28.5,9.7],
            message: keysize,
        },
        {
            ...allkeys?.ArrowRight,
            pointer: [30.2,9.7],
            message: keysize,
        },
        {
            ...allkeys?.Numpad0,
            pointer: [32.2,9.7],
            message: {
                ...keysize,
                w: 3.2,
            }
        }, 
        {
            ...allkeys?.NumpadDecimal,
            pointer: [35.6,9.7],
            message: keysize,
        },
        {
            ...allkeys?.NumpadEnter,
            pointer: [37.3, 8],
            message: {
                ...keysize,
                h: 3.2,
            }
        }, 
    ]
};

export const keys104 = {
    width: 39.8,
    height: 12.4,
    keys: [
        {
            ...allkeys?.Escape,
            pointer: [1,1],
            message: keysize,

        },
        {
            ...allkeys?.F1, 
            pointer: [4.4,1],
            message: keysize,

        },
        {
            ...allkeys?.F2,
            pointer: [6.1,1],
            message: keysize,
        },
        {
            ...allkeys?.F3,
            pointer: [7.8,1],
            message: keysize,
        },
        {
            ...allkeys?.F4,
            pointer: [9.5,1],
            message: keysize,
        },
        {
            ...allkeys?.F5,
            pointer: [12.05,1],
            message: keysize,
        },
        {
            ...allkeys?.F6,
            pointer: [13.75,1],
            message: keysize,
        },
        {
            ...allkeys?.F7,
            pointer: [15.45,1],
            message: keysize,
        },
        {
            ...allkeys?.F8,
            pointer: [17.15,1],
            message: keysize,
        },
        {
            ...allkeys?.F9,
            pointer: [19.7,1],
            message: keysize,
        },
        {
            ...allkeys?.F10,
            pointer: [21.4,1],
            message: keysize,
        },
        {
            ...allkeys?.F11,
            pointer: [23.1,1],
            message: keysize,
        },
        {
            ...allkeys?.F12,
            pointer: [24.8,1],
            message: keysize,
        },
        {
            ...allkeys?.PrintScreen,
            pointer: [26.8,1],
            message: keysize,
        },
        {   
            ...allkeys?.ScrollLock,
            pointer: [28.5,1],
            message: keysize,
        },
        
        {
            ...allkeys?.Pause,
            pointer: [30.2,1],
            message: keysize,
        },
        {
            ...allkeys?.VOLADD,
            pointer: [33.9,1],
            message: keysize,
        },
        {
            ...allkeys?.VOLDEC,
            pointer: [35.6, 1],
            message: keysize,
        },
        {
            ...allkeys?.Mute,
            pointer: [37.3,1],
            message: keysize,
        },
        {
            ...allkeys?.Backquote,
            pointer: [1, 2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit1,
            pointer: [2.7, 2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit2,
            pointer: [4.4,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit3,
            pointer: [6.1,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit4,
            pointer: [7.8,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit5,
            pointer: [9.5,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit6,
            pointer: [11.2,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit7,
            pointer: [12.9,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit8,
            pointer: [14.6,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit9,
            pointer: [16.3,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit0,
            pointer: [18,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Minus,
            pointer: [19.7,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Equal,
            pointer: [21.4,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Backspace,
            pointer: [23.1, 2.9],
            message: {
                ...keysize,
                w: 3.2,
            }
        },
        {
            ...allkeys?.Insert,
            pointer: [26.8, 2.9],
            message: keysize,
        },
        {
            ...allkeys?.Home,
            pointer: [28.5, 2.9],
            message: keysize,
        },
        {
            ...allkeys?.PageUp,
            pointer: [30.2, 2.9],
            message: keysize,
        },
        {
            ...allkeys?.NumLock,
            pointer: [32.2 ,2.9],
            message: keysize,
        },

        {
            ...allkeys?.NumpadDivide,
            pointer: [33.9,2.9],
            message: keysize,
        },
        {
            ...allkeys?.NumpadMultiply,
            pointer: [35.6,2.9],
            message: keysize,
        },
        {
            ...allkeys?.NumpadSubtract,
            pointer: [37.3,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Tab,
            pointer: [1, 4.6],
            message: {
                ...keysize,
                w: 2,
            }
        },
        {
            ...allkeys?.KeyQ,
            pointer: [3.2, 4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyW,
            pointer: [4.9,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyE,
            pointer: [6.6,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyR,
            pointer: [8.3,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyT,
            pointer: [10,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyY,
            pointer: [11.7,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyU,
            pointer: [13.4,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyI,
            pointer: [15.1,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyO,
            pointer: [16.8,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyP,
            pointer: [18.5,4.6],
            message: keysize,
        },
        {
            ...allkeys?.BracketLeft,
            pointer: [20.2,4.6],
            message: keysize,
        },
        {
            ...allkeys?.BracketRight,
            pointer: [21.9,4.6],
            message: keysize,
        },
        {
            ...allkeys?.Backslash,
            pointer: [23.6,4.6],
            message: {
                ...keysize,
                w: 2.7,
            }
        },
        {
            ...allkeys?.Delete,
            pointer: [26.8, 4.6],
            message: keysize,
        },
        {
            ...allkeys?.End,
            pointer: [28.5, 4.6],
            message: keysize,
        },
        {
            ...allkeys?.PageDown,
            pointer: [30.2, 4.6],
            message: keysize,
        },
        {
            ...allkeys?.Numpad7,
            pointer: [32.2,4.6],
            message: keysize,
        },

        {
            ...allkeys?.Numpad8,
            pointer: [33.9,4.6],
            message: keysize,
        },
        {
            ...allkeys?.Numpad9,
            pointer: [35.6,4.6],
            message: keysize,
        },
        {
            ...allkeys?.NumpadAdd,
            pointer: [37.3,4.6],
            message: {
                ...keysize,
                h: 3.2,
            }
        },
        {
            ...allkeys?.CapsLock,
            pointer: [1, 6.3],
            message: {
                ...keysize,
                w: 2.5,
            }
        },
        {
            ...allkeys?.KeyA,
            pointer: [3.7, 6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyS,
            pointer: [5.4,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyD,
            pointer: [7.1,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyF,
            pointer: [8.8,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyG,
            pointer: [10.5,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyH,
            pointer: [12.2,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyJ,
            pointer: [13.9,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyK,
            pointer: [15.6,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyL,
            pointer: [17.3,6.3],
            message: keysize,
        },
        {
            ...allkeys?.Semicolon,
            pointer: [19,6.3],
            message: keysize,
        },
        {
            ...allkeys?.Quote,
            pointer: [20.7,6.3],
            message: keysize,
        },
        {
            ...allkeys?.Enter,
            pointer: [22.4,6.3],
            message: {
                ...keysize,
                w: 3.9,
            }
        },
        {
            ...allkeys?.Numpad4,
            pointer: [32.2,6.3],
            message: keysize,
        },

        {
            ...allkeys?.Numpad5,
            pointer: [33.9,6.3],
            message: keysize,
        },
        {
            ...allkeys?.Numpad6,
            pointer: [35.6,6.3],
            message: keysize,
        },
        {
            ...allkeys?.ShiftLeft,
            pointer: [1, 8],
            message: {
                ...keysize,
                w: 3.5,
            }
        },
        {
            ...allkeys?.KeyZ,
            pointer: [4.7, 8],
            message: keysize,
        },
        {
            ...allkeys?.KeyX,
            pointer: [6.4,8],
            message: keysize,
        },
        {
            ...allkeys?.KeyC,
            pointer: [8.1,8],
            message: keysize,
        },
        {
            ...allkeys?.KeyV,
            pointer: [9.8,8],
            message: keysize,
        },
        {
            ...allkeys?.KeyB,
            pointer: [11.5,8],
            message: keysize,
        },
        {
            ...allkeys?.KeyN,
            pointer: [13.2,8],
            message: keysize,
        },
        {
            ...allkeys?.KeyM,
            pointer: [14.9,8],
            message: keysize,
        },
        {
            ...allkeys?.Comma,
            pointer: [16.6,8],
            message: keysize,
        },
        {
            ...allkeys?.Period,
            pointer: [18.3,8],
            message: keysize,
        },
        {
            ...allkeys?.Slash,
            pointer: [20,8],
            message: keysize,
        },
        {
            ...allkeys?.ShiftRight,
            pointer: [21.7,8],
            message: {
                ...keysize,
                w: 4.6,
            }
        },
        {
            ...allkeys?.ArrowUp,
            pointer: [28.5,8],
            message: keysize,
        },
        {
            ...allkeys?.Numpad1,
            pointer: [32.2,8],
            message: keysize,
        },

        {
            ...allkeys?.Numpad2,
            pointer: [33.9,8],
            message: keysize,
        },
        {
            ...allkeys?.Numpad3,
            pointer: [35.6,8],
            message: keysize,
        },
        {
            ...allkeys?.ControlLeft,
            pointer: [1,9.7],
            message: {
                ...keysize,
                w: 1.6,
            }
        },
        {
            ...allkeys?.MetaLeft,
            pointer: [2.8,9.7],
            message: {
                ...keysize,
                w: 1.6,
            }
        },
        {
            ...allkeys?.AltLeft,
            pointer: [4.6,9.7],
            message: {
                ...keysize,
                w: 1.6,
            }
        },
        {
            ...allkeys?.Space,
            pointer: [6.4,9.7],
            message: {
                ...keysize,
                w: 12.7,
            }
        },
        {
            ...allkeys?.AltRight,
            pointer: [19.3,9.7],
            message: {
                ...keysize,
                w: 1.6,
            }
        },
        {
            ...allkeys?.WakeUp,
            pointer: [21.1,9.7],
            message: {
                ...keysize,
                w: 1.6,
            }
        },
        {
            ...allkeys?.App,
            pointer: [22.9,9.7],
            message: {
                ...keysize,
                w: 1.6,
            }
        },
        {
            ...allkeys?.ControlRight,
            pointer: [24.7,9.7],
            message: {
                ...keysize,
                w: 1.6,
            }
        },
        
        {
            ...allkeys?.ArrowLeft,
            pointer: [26.7,9.7],
            message: keysize,
        },
        {
            ...allkeys?.ArrowDown,
            pointer: [28.5,9.7],
            message: keysize,
        },
        {
            ...allkeys?.ArrowRight,
            pointer: [30.2,9.7],
            message: keysize,
        },
        {
            ...allkeys?.Numpad0,
            pointer: [32.2,9.7],
            message: {
                ...keysize,
                w: 3.2,
            }
        }, 
        {
            ...allkeys?.NumpadDecimal,
            pointer: [35.6,9.7],
            message: keysize,
        },
        {
            ...allkeys?.NumpadEnter,
            pointer: [37.3, 8],
            message: {
                ...keysize,
                h: 3.2,
            }
        }, 
    ]
};

export const keys98 = {
    width: 34.7,
    height: 12.4,
    keys: [
        {
            ...allkeys?.Escape,
            pointer: [1,1],
            message: keysize,
        },
        {
            ...allkeys?.F1,
            pointer: [2.7,1],
            message: keysize,
        },
        {
            ...allkeys?.F2,
            pointer: [4.4,1],
            message: keysize,
        },
        {
            ...allkeys?.F3,
            pointer: [6.1,1],
            message: keysize,
        },
        {
            ...allkeys?.F4,
            pointer: [7.8,1],
            message: keysize,
        },
        {
            ...allkeys?.F5,
            pointer: [9.5,1],
            message: keysize,
        },
        {
            ...allkeys?.F6,
            pointer: [11.2,1],
            message: keysize,
        },
        {
            ...allkeys?.F7,
            pointer: [12.9,1],
            message: keysize,
        },
        {
            ...allkeys?.F8,
            pointer: [14.6,1],
            message: keysize,
        },
        {
            ...allkeys?.F9,
            pointer: [16.3,1],
            message: keysize,
        },
        {
            ...allkeys?.F10,
            pointer: [18,1],
            message: keysize,
        },
        {
            ...allkeys?.F11,
            pointer: [19.7,1],
            message: keysize,
        },
        {
            ...allkeys?.F12,
            pointer: [21.4,1],
            message: keysize,
        },
        {
            ...allkeys?.Delete,
            pointer: [23.1,1],
            message: keysize,
        },
        {
            ...allkeys?.Home,
            pointer: [24.8,1],
            message: keysize,
        },
        
        {
            ...allkeys?.End,
            pointer: [27.1,1],
            message: keysize,
        },
        {
            ...allkeys?.PageUp,
            pointer: [28.8, 1],
            message: keysize,
        },
        {
            ...allkeys?.PageDown,
            pointer: [30.5,1],
            message: keysize,
        },
        {
            ...allkeys?.Backquote,
            pointer: [1, 2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit1,
            pointer: [2.7, 2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit2,
            pointer: [4.4,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit3,
            pointer: [6.1,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit4,
            pointer: [7.8,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit5,
            pointer: [9.5,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit6,
            pointer: [11.2,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit7,
            pointer: [12.9,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit8,
            pointer: [14.6,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit9,
            pointer: [16.3,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Digit0,
            pointer: [18,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Minus,
            pointer: [19.7,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Equal,
            pointer: [21.4,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Backspace,
            pointer: [23.1, 2.9],
            message: {
                ...keysize,
                w: 3.2,
            }
        },
        {
            ...allkeys?.NumLock,
            pointer: [27.1,2.9],
            message: keysize,
        },

        {
            ...allkeys?.NumpadDivide,
            pointer: [28.8,2.9],
            message: keysize,
        },
        {
            ...allkeys?.NumpadMultiply,
            pointer: [30.5,2.9],
            message: keysize,
        },
        {
            ...allkeys?.NumpadSubtract,
            pointer: [32.3,2.9],
            message: keysize,
        },
        {
            ...allkeys?.Tab,
            pointer: [1, 4.6],
            message: {
                ...keysize,
                w: 1.8,
            }
        },
        {
            ...allkeys?.KeyQ,
            pointer: [3, 4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyW,
            pointer: [4.7,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyE,
            pointer: [6.4,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyR,
            pointer: [8.1,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyT,
            pointer: [9.8,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyY,
            pointer: [11.5,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyU,
            pointer: [13.2,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyI,
            pointer: [14.9,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyO,
            pointer: [16.6,4.6],
            message: keysize,
        },
        {
            ...allkeys?.KeyP,
            pointer: [18.3,4.6],
            message: keysize,
        },
        {
            ...allkeys?.BracketLeft,
            pointer: [20,4.6],
            message: keysize,
        },
        {
            ...allkeys?.BracketRight,
            pointer: [21.7,4.6],
            message: keysize,
        },
        {
            ...allkeys?.Backslash,
            pointer: [23.4,4.6],
            message: {
                ...keysize,
                w: 2.9,
            }
        },
        {
            ...allkeys?.Numpad7,
            pointer: [27.1,4.6],
            message: keysize,
        },

        {
            ...allkeys?.Numpad8,
            pointer: [28.8,4.6],
            message: keysize,
        },
        {
            ...allkeys?.Numpad9,
            pointer: [30.5,4.6],
            message: keysize,
        },
        {
            ...allkeys?.NumpadAdd,
            pointer: [32.3,4.6],
            message: {
                ...keysize,
                h: 3.2,
            }
        },
        {
            ...allkeys?.CapsLock,
            pointer: [1, 6.3],
            message: {
                ...keysize,
                w: 2.5,
            }
        },
        {
            ...allkeys?.KeyA,
            pointer: [3.7, 6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyS,
            pointer: [5.4,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyD,
            pointer: [7.1,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyF,
            pointer: [8.8,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyG,
            pointer: [10.5,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyH,
            pointer: [12.2,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyJ,
            pointer: [13.9,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyK,
            pointer: [15.6,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyL,
            pointer: [17.3,6.3],
            message: keysize,
        },
        {
            ...allkeys?.Semicolon,
            pointer: [19,6.3],
            message: keysize,
        },
        {
            ...allkeys?.Quote,
            pointer: [20.7,6.3],
            message: keysize,
        },
        {
            ...allkeys?.Enter,
            pointer: [22.4,6.3],
            message: {
                ...keysize,
                w: 3.9,
            }
        },
        {
            ...allkeys?.ShiftLeft,
            pointer: [1, 8],
            message: {
                ...keysize,
                w: 3,
            }
        },
        {
            ...allkeys?.Numpad4,
            pointer: [27.1,6.3],
            message: keysize,
        },

        {
            ...allkeys?.Numpad5,
            pointer: [28.8,6.3],
            message: keysize,
        },
        {
            ...allkeys?.Numpad6,
            pointer: [30.5,6.3],
            message: keysize,
        },
        {
            ...allkeys?.KeyZ,
            pointer: [4.2, 8],
            message: keysize,
        },
        {
            ...allkeys?.KeyX,
            pointer: [5.9,8],
            message: keysize,
        },
        {
            ...allkeys?.KeyC,
            pointer: [7.6,8],
            message: keysize,
        },
        {
            ...allkeys?.KeyV,
            pointer: [9.3,8],
            message: keysize,
        },
        {
            ...allkeys?.KeyB,
            pointer: [11,8],
            message: keysize,
        },
        {
            ...allkeys?.KeyN,
            pointer: [12.7,8],
            message: keysize,
        },
        {
            ...allkeys?.KeyM,
            pointer: [14.4,8],
            message: keysize,
        },
        {
            ...allkeys?.Comma,
            pointer: [16.1,8],
            message: keysize,
        },
        {
            ...allkeys?.Period,
            pointer: [17.8,8],
            message: keysize,
        },
        {
            ...allkeys?.Slash,
            pointer: [19.5,8],
            message: keysize,
        },
        {
            ...allkeys?.ShiftRight,
            pointer: [21.2,8],
            message: {
                ...keysize,
                w: 3,
            }
        },
        {
            ...allkeys?.ArrowUp,
            pointer: [25,8],
            message: keysize,
        },
        {
            ...allkeys?.Numpad1,
            pointer: [27.1,8],
            message: keysize,
        },

        {
            ...allkeys?.Numpad2,
            pointer: [28.8,8],
            message: keysize,
        },
        {
            ...allkeys?.Numpad3,
            pointer: [30.5,8],
            message: keysize,
        },
        {
            ...allkeys?.ControlLeft,
            pointer: [1,9.7],
            message: {
                ...keysize,
                w: 2,
            }
        },
        {
            ...allkeys?.MetaLeft,
            pointer: [3.2,9.7],
            message: {
                ...keysize,
                w: 2,
            }
        },
        {
            ...allkeys?.AltLeft,
            pointer: [5.4,9.7],
            message: {
                ...keysize,
                w: 2,
            }
        },
        {
            ...allkeys?.Space,
            pointer: [7.6,9.7],
            message: {
                ...keysize,
                w: 8.3,
            }
        },
        {
            ...allkeys?.AltRight,
            pointer: [16.1,9.7],
            message: {
                ...keysize,
                w: 2,
            }
        },
        {
            ...allkeys?.WakeUp,
            pointer: [18.3,9.7],
            message: {
                ...keysize,
                w: 2,
            }
        },
        {
            ...allkeys?.ControlRight,
            pointer: [20.5,9.7],
            message: {
                ...keysize,
                w: 2,
            }
        },
        
        {
            ...allkeys?.ArrowLeft,
            pointer: [23.3,9.7],
            message: keysize,
        },
        {
            ...allkeys?.ArrowDown,
            pointer: [25,9.7],
            message: keysize,
        },
        {
            ...allkeys?.ArrowRight,
            pointer: [26.7,9.7],
            message: keysize,
        },
        {
            ...allkeys?.Numpad0,
            pointer: [28.8,9.7],
            message: keysize,
        }, 
        {
            ...allkeys?.NumpadDecimal,
            pointer: [30.5,9.7],
            message: keysize,
        },
        {
            ...allkeys?.NumpadEnter,
            pointer: [32.3, 8],
            message: {
                ...keysize,
                h: 3.2,
            }
        }, 
    ]
};



// 按键设备 映射
export const keysTypes:any = {
    // Gaming Keyboard
    '12288': keys98,
    // Z108 有线
    '12410': keys108,
    '49271': keys104, //Wireless Receiver (Z108 无线)
    '12312': keys104,
    '12422': keys104
}

// 按键类型
const keyTypeNums:any = {
    'GamingKeyboard': {
       kbbase:'0x80',
       kbclick:'0x81',
       kbmedia:'0x90',
       kbsys:'0xa0',
       msbase:'0xb0',
       msre:'0xb1',
       msdpi:'0xb6',
       msscroll:'0xb2',
       msh:'0xb3',
       hong1: '0Xc0',
       hong2: '0Xc1',
       fun: '0xd0',
       text: '0xd1',
       fn: '0xe0',
    },
    'Z108': {
        kbbase:'0x10',
        kbclick:'0x11',
        kbmedia:'0x20',
        kbsys:'0x30',
        msbase:'0x40',
        msre:'0x41',
        msscroll:'0x42',
        msh:'0x43',
        msdpi:'0x46',
        hong1: '0X50',
        hong2: '0X51',
        fun: '0x60',
        text: '0x61',
        fn: '0x70',
     }
};

export const keyTypeNum = {
    k: {
        ...keyTypeNums[`GamingKeyboard`]
    },
    get(str: string) {
        return str && this?.k?.[str] ? this?.k?.[str]:'';
    },
    set(pid:string) {
        const id:string = pid === '49288' ? 'GamingKeyboard' : "Z108";
        this.k = id && keyTypeNums?.[id] ? {...keyTypeNums?.[id]}:{};
    },
    getCodeValue() {
        return {
            'PrintScreen': [this?.k?.['kbbase'], "0x00", "0x46"],
            'ScrollLock': [this?.k?.['kbbase'], "0x00", "0x47"],
            'Pause': [this?.k?.['kbbase'], "0x00", "0x48"],
            'Insert': [this?.k?.['kbbase'], "0x00", "0x49"],
            'Escape': [this?.k?.['kbbase'], "0x00", "0x29"],
            'App': [this?.k?.['kbbase'], "0x00", "0x65"],
            'F1': [this?.k?.['kbbase'], "0x00", "0x3a"],
            'F2': [this?.k?.['kbbase'], "0x00", "0x3b"],
            'F3': [this?.k?.['kbbase'], "0x00", "0x3c"],
            'F4': [this?.k?.['kbbase'], "0x00", "0x3d"],
            'F5': [this?.k?.['kbbase'], "0x00", "0x3e"],
            'F6': [this?.k?.['kbbase'], "0x00", "0x3f"],
            'F7': [this?.k?.['kbbase'], "0x00", "0x40"],
            'F8': [this?.k?.['kbbase'], "0x00", "0x41"],
            'F9': [this?.k?.['kbbase'], "0x00", "0x42"],
            'F10': [this?.k?.['kbbase'], "0x00", "0x43"],
            'F11': [this?.k?.['kbbase'], "0x00", "0x44"],
            'F12': [this?.k?.['kbbase'], "0x00", "0x45"],
            'Delete': [this?.k?.['kbbase'], "0x00", "0x4c"],
            'Home': [this?.k?.['kbbase'], "0x00", "0x4a"],
            'End': [this?.k?.['kbbase'], "0x00", "0x4d"],
            'PageUp': [this?.k?.['kbbase'], "0x00", "0x4b"],
            'PageDown': [this?.k?.['kbbase'], "0x00", "0x4e"],
            'Backquote': [this?.k?.['kbbase'], "0x00", "0x35"], //['`', '~']
            'Digit1': [this?.k?.['kbbase'], "0x00", "0x1e"],
            'Digit2': [this?.k?.['kbbase'], "0x00", "0x1f"],
            'Digit3': [this?.k?.['kbbase'], "0x00", "0x20"],
            'Digit4': [this?.k?.['kbbase'], "0x00", "0x21"],
            'Digit5': [this?.k?.['kbbase'], "0x00", "0x22"],
            'Digit6': [this?.k?.['kbbase'], "0x00", "0x23"],
            'Digit7': [this?.k?.['kbbase'], "0x00", "0x24"],
            'Digit8': [this?.k?.['kbbase'], "0x00", "0x25"],
            'Digit9': [this?.k?.['kbbase'], "0x00", "0x26"],
            'Digit0': [this?.k?.['kbbase'], "0x00", "0x27"],
            'Minus': [this?.k?.['kbbase'], "0x00", "0x2d"], //['-','_']
            'Equal': [this?.k?.['kbbase'], "0x00", "0x2e"], //['=','+']
            'Backspace': [this?.k?.['kbbase'], "0x00", "0x2a"],
            'NumLock': [this?.k?.['kbbase'], "0x00", "0x53"],
            'NumpadDivide': [this?.k?.['kbbase'], "0x00", "0x54"], //['/']
            'NumpadMultiply': [this?.k?.['kbbase'], "0x00", "0x55"], //['*']
            'NumpadSubtract': [this?.k?.['kbbase'], "0x00", "0x56"], //['-']
            'Tab': [this?.k?.['kbbase'], "0x00", "0x2b"],
            'BracketLeft': [this?.k?.['kbbase'], "0x00", "0x2f"], //['[','{']
            'BracketRight': [this?.k?.['kbbase'], "0x00", "0x30"], //[']', '}']
            'Backslash': [this?.k?.['kbbase'], "0x00", "0x31"], //['\', '|']
            'Numpad7': [this?.k?.['kbbase'], "0x00", "0x5f"],
            'Numpad8': [this?.k?.['kbbase'], "0x00", "0x60"],
            'Numpad9': [this?.k?.['kbbase'], "0x00", "0x61"],
            'NumpadAdd': [this?.k?.['kbbase'], "0x00", "0x57"],
            'CapsLock': [this?.k?.['kbbase'], "0x00", "0x39"], //['CAPSLK']
            'KeyA': [this?.k?.['kbbase'], "0x00", "0x04"],
            'KeyB': [this?.k?.['kbbase'], "0x00", "0x05"],
            'KeyC': [this?.k?.['kbbase'], "0x00", "0x06"],
            'KeyD': [this?.k?.['kbbase'], "0x00", "0x07"],
            'KeyE': [this?.k?.['kbbase'], "0x00", "0x08"],
            'KeyF': [this?.k?.['kbbase'], "0x00", "0x09"],
            'KeyG': [this?.k?.['kbbase'], "0x00", "0x0A"],
            'KeyH': [this?.k?.['kbbase'], "0x00", "0x0B"],
            'KeyI': [this?.k?.['kbbase'], "0x00", "0x0C"],
            'KeyJ': [this?.k?.['kbbase'], "0x00", "0x0d"],
            'KeyK': [this?.k?.['kbbase'], "0x00", "0x0e"],
            'KeyL': [this?.k?.['kbbase'], "0x00", "0x0f"],
            'KeyM': [this?.k?.['kbbase'], "0x00", "0x10"],
            'KeyN': [this?.k?.['kbbase'], "0x00", "0x11"], //
            'KeyO': [this?.k?.['kbbase'], "0x00", "0x12"],
            'KeyP': [this?.k?.['kbbase'], "0x00", "0x13"],
            'KeyQ': [this?.k?.['kbbase'], "0x00", "0x14"],  
            'KeyR': [this?.k?.['kbbase'], "0x00", "0x15"],
            'KeyS': [this?.k?.['kbbase'], "0x00", "0x16"],
            'KeyT': [this?.k?.['kbbase'], "0x00", "0x17"],
            'KeyU': [this?.k?.['kbbase'], "0x00", "0x18"],
            'KeyV': [this?.k?.['kbbase'], "0x00", "0x19"],
            'KeyW': [this?.k?.['kbbase'], "0x00", "0x1a"],   
            'KeyX': [this?.k?.['kbbase'], "0x00", "0x1b"],
            'KeyY': [this?.k?.['kbbase'], "0x00", "0x1c"],
            'KeyZ': [this?.k?.['kbbase'], "0x00", "0x1d"],
            'Semicolon': [this?.k?.['kbbase'], "0x00", "0x33"], //[';',':']
            'Quote': [this?.k?.['kbbase'], "0x00", "0x34"], //[''','"']
            'Enter': [this?.k?.['kbbase'], "0x00", "0x28"],
            'ShiftLeft': [this?.k?.['kbbase'], "0x02", "0x00"],
            'Numpad4': [this?.k?.['kbbase'], "0x00", "0x5c"],
            'Numpad5': [this?.k?.['kbbase'], "0x00", "0x5d"],
            'Numpad6': [this?.k?.['kbbase'], "0x00", "0x5e"],
            'Comma': [this?.k?.['kbbase'], "0x00", "0x36"], // [',','<']
            'Period': [this?.k?.['kbbase'], "0x00", "0x37"], // ['.','>']
            'Slash': [this?.k?.['kbbase'], "0x00", "0x38"], // '/','?'
            'ShiftRight': [this?.k?.['kbbase'], "0x20", "0x00"],
            'ArrowUp': [this?.k?.['kbbase'], "0x00", "0x52"],
            'Numpad1': [this?.k?.['kbbase'], "0x00", "0x59"],
            'Numpad2': [this?.k?.['kbbase'], "0x00", "0x5a"],
            'Numpad3': [this?.k?.['kbbase'], "0x00", "0x5b"],
            'ControlLeft': [this?.k?.['kbbase'], "0x01", "0x00"],
            'MetaLeft': [this?.k?.['kbbase'], "0x08", "0x00"],
            'AltLeft': [this?.k?.['kbbase'], "0x04", "0x00"],
            'Space': [this?.k?.['kbbase'], "0x00", "0x2c"],
            'AltRight': [this?.k?.['kbbase'], "0x40", "0x00"],
            'MetaRight': [this?.k?.['kbbase'], "0x80", "0x00"],
            'ControlRight': [this?.k?.['kbbase'], "0x10", "0x00"],
            'ArrowLeft': [this?.k?.['kbbase'], "0x00", "0x50"],
            'ArrowDown': [this?.k?.['kbbase'], "0x00", "0x51"],
            'ArrowRight': [this?.k?.['kbbase'], "0x00", "0x4f"],
            'Numpad0': [this?.k?.['kbbase'], "0x00", "0x62"],
            'NumpadDecimal': [this?.k?.['kbbase'], "0x00", "0x63"],
            'NumpadEnter': [this?.k?.['kbbase'], "0x00", "0x58"],
            "WakeUp": [this?.k?.['fun'], "0x01", "0x00"], 
            'K42': [this?.k?.['kbbase'], "0x00", "0x32"],
            'K45': [this?.k?.['kbbase'], "0x00", "0x64"],
            'K107': [this?.k?.['kbbase'], "0x00", "0x85"],
            'K56': [this?.k?.['kbbase'], "0x00", "0x87"],
            'K133': [this?.k?.['kbbase'], "0x00", "0x88"],
            'K14': [this?.k?.['kbbase'], "0x00", "0x89"],
            'K132': [this?.k?.['kbbase'], "0x00", "0x8a"],
            'K131': [this?.k?.['kbbase'], "0x00", "0x8b"],
            'K151': [this?.k?.['kbbase'], "0x00", "0x90"],
            'K150': [this?.k?.['kbbase'], "0x00", "0x91"],
            "MEDIA": [this?.k?.['kbmedia'], "0x83", "0x01"],//多媒体
            "PLAY": [this?.k?.['kbmedia'], "0xCD", "0x00"],//播放
            "STOP": [this?.k?.['kbmedia'], "0xb7", "0x00"],//播放停止
            "PREV":	[this?.k?.['kbmedia'], "0xb6", "0x00"],//上一曲
            "NEXT": [this?.k?.['kbmedia'], "0xb5", "0x00"],//下一曲
            "VOL_DEC": [this?.k?.['kbmedia'], "0xea", "0x00"],//音量加
            "VOL_ADD": [this?.k?.['kbmedia'], "0xe9", "0x00"],//音量减
            "Mute": [this?.k?.['kbmedia'], "0xe2", "0x00"],//静音
            "WEB": [this?.k?.['kbmedia'], "0x23", "0x02"],//网页
            "WEB_REFRESH": [this?.k?.['kbmedia'], "0x27", "0x02"],//刷新
            "WEB_STOP": [this?.k?.['kbmedia'], "0x26", "0x02"],//网页停止
            "WEB_BACK":	[this?.k?.['kbmedia'], "0x24", "0x02"],//网页返回
            "WEB_FORWARD": [this?.k?.['kbmedia'], "0x25", "0x02"],//网页前进
            "WEB_FAVORITE": [this?.k?.['kbmedia'], "0x2a", "0x02"],//网页收藏
            "SEARCH": [this?.k?.['kbmedia'], "0x21", "0x02"],//搜索
            "WEB_COMPUTER":	[this?.k?.['kbmedia'], "0x94", "0x01"],//我的电脑
            "Calc": [this?.k?.['kbmedia'], "0x92", "0x01"],//计算器0x8a
            "MAIL":	[this?.k?.['kbmedia'], "0x8a", "0x01"],//邮件
            "POWER": [this?.k?.['kbsys'], "0x01", "0x00"], //电源
            "SLEEP": [this?.k?.['kbsys'], "0x02", "0x00"], //睡眠
            "WAKE": [this?.k?.['kbsys'], "0x04", "0x00"], //唤醒
            "OFFICE_OPEN": [this?.k?.['fn'], "0x01", "0x12"], //打开
            "OFFICE_NEW": [this?.k?.['fn'], "0x01", "0x11"], //新建
            "OFFICE_UNDO": [this?.k?.['fn'], "0x01", "0x1d"], //重做
            "OFFICE_SAVE": [this?.k?.['fn'], "0x01", "0x16"], //保存
            "OFFICE_COPY": [this?.k?.['fn'], "0x01", "0x06"], //复制
            "OFFICE_CUT": [this?.k?.['fn'], "0x01", "0x1b"], //剪切
            "OFFICE_PASTE": [this?.k?.['fn'], "0x01", "0x19"], //粘贴
            "OFFICE_FIND": [this?.k?.['fn'], "0x01", "0x09"], //查找
            "OFFICE_CHECKED_ALL": [this?.k?.['fn'], "0x01", "0x04"], //全选
            "OFFICE_DEC": [this?.k?.['fn'], "0x01", "0x2d"], //缩小
            "OFFICE_ADD": [this?.k?.['fn'], "0x01", "0x2e"], //放大
            "MS_LEFT": [this?.k?.['msbase'], "0x01", "0x00"],//左键 
            "MS_RIGHT": [this?.k?.['msbase'], "0x02", "0x00"],//右键
            "MS_MIDDLE": [this?.k?.['msbase'], "0x04", "0x00"],	//中键
            "MS_FORWORD_KEY": [this?.k?.['msbase'], "0x10", "0x00"],// 前进
            "MS_BACK_KEY": [this?.k?.['msbase'], "0x08", "0x00"],//后退
            "MS_DPI_UP": [this?.k?.['msdpi'], "0x01", "0x00"],//DPI +
            "MS_DPI_DOWN": [this?.k?.['msdpi'], "0x02", "0x00"],//DPI -
            "MS_DPI_LOOP ": [this?.k?.['msdpi'], "0x03", "0x00"],//DPI 循环
            // "MS_DPI_SHIFT": [this?.k?.['fn'], "0x01", "0x2d"]
            "OFFICE_LCTRL": [this?.k?.['fn'], "0x01"], //LCTRL
            "OFFICE_RCTRL": [this?.k?.['fn'], "0x10"], //RCTRL
            "OFFICE_LSHIFT": [this?.k?.['fn'], "0x02"], //LSHIFT
            "OFFICE_RSHIFT": [this?.k?.['fn'], "0x20"], //RSHIFT
            "OFFICE_LALT": [this?.k?.['fn'], "0x04"], //LALT
            "OFFICE_RALT": [this?.k?.['fn'], "0x40"], //RALT
            "OFFICE_LWIN": [this?.k?.['fn'], "0x08"], //LWIN
            "OFFICE_RWIN": [this?.k?.['fn'], "0x80"], //RWIN
            "HONG":[this?.k?.['hong1']],
        };
    }
}



export const other = [
    {
        key: 'kb_6key_mode',
        text: 'kb_6key_mode', 
        component: 'switch',
    },
    {
        key: 'win_flag',
        text: 'win_flag', 
        component: 'switch'
    },
    {
        key: 'D24G_OFF_LED_TIME',
        text: 'D24G_OFF_LED_TIME', 
        component: 'defineTime'
    },
    {
        key: 'BLE_OFF_LED_TIME',
        text: 'BLE_OFF_LED_TIME', 
        component: 'defineTime'
    },
    {
        key: 'D24G_SLEEP_TIME',
        text: 'D24G_SLEEP_TIME', 
        component: 'defineTime'
    },
    {
        key: 'BLE_SLEEP_TIME',
        text: 'BLE_SLEEP_TIME', 
        component: 'defineTime'
    },
    {
        key: 'USB_OFF_LED_TIME',
        text: 'USB_OFF_LED_TIME', 
        component: 'defineTime'
    }
    // ,
    // {
    //     key: `8`,
    //     text: '按键灵敏度', 
    //     component: 'otherSlider'
    // }

]

const Text = () => {
    return {
        other,
        keysTypes,
        keyTypeNum
    };
    
};

export default Text;