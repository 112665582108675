import Type from './type.ts';
import { exportJSON } from '../../common/global.ts'
// export const getSettings = () => {

//     return {
//         type: type.getDatas,

//     };
// }

// export const getSetting = () => {
    
//     return {
        
//     }
// }
export const installSetting = (productId:string, defaultsetting:any, settings:any) => {
    return {
        type: Type.install,
        productId,
        defaultsetting_n: defaultsetting,
        settings,
        checkedProFile: settings[0].key
    }
}

export const addSetting = () => {  
    return {
        type: Type.add,
    }
}
export const doImportSetting = (importsetting:any) => {  
    return {
        type: Type.Import,
        importsetting
    }
}

export const editSetting = (key: string, value: string) => {
    return {
        type: Type.edit,
        key,
        value
    }
}

export const deleteSetting = (key: string) => {
    return {
        type: Type.delete,
        key
    }
}

export const copySetting = (key: string) => {
    
    return {
        type: Type.copy,
        key
    }
}

export const restSetting = (key: string) => {
    return {
        type: Type.rest,
        key,
    }
}

export const setHongName = (hongName: string) => {
    return {
        type: Type.setHongName,
        hongName,
    }
}
export const setTySetting = (tysetting: any) => {
    return {
        type: Type.setTySetting,
        tysetting,
    }
}
export const changeKeyRect = (arr:any) => {
    return {
        type: Type.changeKeyRect,
        keyRect: arr,
    }
}

export const exportSetting = (data: string) => {
    exportJSON(data, `${new Date().getTime()}.json`)
}

export const ImportSetting = (data: any) => {
    exportJSON(data, `${new Date().getTime()}.json`)
}

export const setCheckedBtn = (checkedKey:string) => {
    return {
        type: Type.checkedKey,
        checkedKey
    }
}

export const chooseProfile = (checkedProFile:string) => {
    return {
        type: Type.checkedProFile,
        checkedProFile
    }
}