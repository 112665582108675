import React from 'react';
import {InputBtns} from '../../../common/component/btns/btns.tsx'


const SetBoardText = (props:any) => {
    const changF = () => {
        return props?.data ? [...props?.data].map((item, i) => ({
            key: i,
            text: `${item.Pointer[0]}`,
            value: item.code
        })):[]
    }
    
    const handClick = (data:any) => {
       if (props?.onClick) props.onClick(data)

    }
    return <div className='key-board'>
        <>
            {InputBtns(changF(), (data:any) => handClick && handClick(data))}
       </>
    </div>
}

SetBoardText.displayName = 'SetBoardText';
export default SetBoardText;