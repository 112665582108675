

// 键盘信息
const keys = {
    keyBoard:[
        {
          key: 1,
          Pointer: ['LCtrl'],
          code: 'ControlLeft',
        },
        {
          key: 2,
          Pointer: ['LShift'],
          code: 'ShiftLeft',
        },
        {
          key: 3,
          Pointer: ['LAlt'],
          code: 'AltLeft',
        },
        {
          key: 4,
          Pointer: ['LWin'],
          code: 'MetaLeft',
        },
        {
          key: 5,
          Pointer: ['RCtrl'],
          code: 'ControlRight',
        },
        {
          key: 6,
          Pointer: ['RShift'],
          code: 'ShiftRight',
        },
        {
          key: 7,
          Pointer: ['RAlt'],
          code: 'AltRight',
        },
        {
          key: 8,
          Pointer: ['RWin'],
          code: 'MetaRight',
        },
        {
          key: 9,
          Pointer: ['A'],
          code: 'KeyA'
        },
        {
          key: 10,
          Pointer: ['B'],
          code: 'KeyB'
        },
        {
          key: 11,
          Pointer: ['C'],
          code: 'KeyC'
        },
        {
          key: 12,
          Pointer: ['D'],
          code: 'KeyD'
        },
        {
          key: 13,
          Pointer: ['E'],
          code: 'KeyE'
        },
        {
          key: 14,
          Pointer: ['F'],
          code: 'KeyF'
        },
        {
          key: 15,
          Pointer: ['G'],
          code: 'KeyG'
        },
        {
          key: 16,
          Pointer: ['H'],
          code: 'KeyH'
        },
        {
            key: 17,
            Pointer: ['I'],
            code: 'KeyI'
          },
          {
            key: 18,
            Pointer: ['J'],
            code: 'KeyJ'
          },
          {
            key: 19,
            Pointer: ['K'],
            code: 'KeyK'
          },
          {
            key: 20,
            Pointer: ['L'],
            code: 'KeyL'
          },
          {
            key: 21,
            Pointer: ['M'],
            code: 'KeyM'
          },
          {
            key: 22,
            Pointer: ['N'],
            code: 'KeyN'
          },
          {
            key: 23,
            Pointer: ['O'],
            code: 'KeyO'
          },
          {
            key: 24,
            Pointer: ['P'],
            code: 'KeyP'
          },
          {
            key: 25,
            Pointer: ['Q'],
            code: 'KeyQ'
          },
          {
            key: 26,
            Pointer: ['R'],
            code: 'KeyR'
          },
          {
            key: 27,
            Pointer: ['S'],
            code: 'KeyS'
          },
          {
            key: 28,
            Pointer: ['T'],
            code: 'KeyT'
          },
          {
            key: 29,
            Pointer: ['U'],
            code: 'KeyU'
          },
          {
            key: 30,
            Pointer: ['V'],
            code: 'KeyV'
          },
          {
            key: 31,
            Pointer: ['W'],
            code: 'KeyW'
          },
          {
            key: 32,
            Pointer: ['X'],
            code: 'KeyX'
          },
          {
            key: 33,
            Pointer: ['Y'],
            code: 'KeyY'
          },
          {
            key: 34,
            Pointer: ['Z'],
            code: 'KeyZ'
          },
          {
            key: 35,
            Pointer: ['1'],
            code: 'Digit1'
          },
          {
            key: 36,
            Pointer: ['2'],
            code: 'Digit2'
          },
          {
            key: 37,
            Pointer: ['3'],
            code: 'Digit3'
          },
          {
            key: 38,
            Pointer: ['4'],
            code: 'Digit4'
          },
          {
            key: 39,
            Pointer: ['5'],
            code: 'Digit5'
          },
          {
            key: 40,
            Pointer: ['6'],
            code: 'Digit6'
          },
          {
            key: 41,
            Pointer: ['7'],
            code: 'Digit7'
          },
          {
            key: 42,
            Pointer: ['8'],
            code: 'Digit8'
          },
          {
            key: 43,
            Pointer: ['9'],
            code: 'Digit9'
          },
          {
            key: 44,
            Pointer: ['0'],
            code: 'Digit0'
          },
         {
          key: 45,
          Pointer: ['Enter'],
          code: 'Enter'
        },
        {
          key: 46,
          Pointer: ['Esc'],
          code: 'Escape'
        },
        {
          key: 47,
          Pointer: ['Backs'],
          code: 'Backspace'
        },
        {
          key: 48,
          Pointer: ['Tab'],
          code: 'Tab'
        },
        {
          key: 49,
          Pointer: ['Space'],
          code: 'Space'
        },
        {
          key: 50,
          Pointer: ['-'],
          code: 'Minus'
        },
        {
          key: 51,
          Pointer: ['='],
          code: 'Equal'
        },
        {
          key: 52,
          Pointer: ['['],
          code: 'BracketLeft'
        },
        {
          key: 52,
          Pointer: [']'],
          code: 'BracketRight'
        },
        {
          key: 54,
          Pointer: ['|\\'],
          code: 'Backslash'
        },
        {
          key: 55,
          Pointer: [';'],
          code: 'Semicolon'
        },
        {
          key: 56,
          Pointer: ['\''],
          code: 'Quote'
        },
        {
          key: 57,
          Pointer: ['`'],
          code: 'Backquote'
        },
        {
          key: 58,
          Pointer: [','],
          code: 'Comma'
        },
        {
          key: 59,
          Pointer: ['.'],
          code: 'Period'
        },
        {
          key: 60,
          Pointer: ['/'],
          code: 'Slash'
        },
        {
          key: 61,
          Pointer: ['Caps'],
          code: 'CapsLock'
        },
        {
          key: 62,
          Pointer: ['F1'],
          code: 'F1'
        },
        {
          key: 63,
          Pointer: ['F2'],
          code: 'F2'
        },
        {
          key: 64,
          Pointer: ['F3'],
          code: 'F3'
        },
        {
          key: 65,
          Pointer: ['F4'],
          code: 'F4'
        },
        {
          key: 66,
          Pointer: ['F5'],
          code: 'F5'
        },
        {
          key: 67,
          Pointer: ['F6'],
          code: 'F6'
        },
        {
          key: 68,
          Pointer: ['F7'],
          code: 'F7'
        },
        {
          key: 69,
          Pointer: ['F8'],
          code: 'F8'
        },
        {
          key: 70,
          Pointer: ['F9'],
          code: 'F9'
        },
        {
          key: 71,
          Pointer: ['F10'],
          code: 'F10'
        },
        {
          key: 72,
          Pointer: ['F11'],
          code: 'F11'
        },
        {
          key: 73,
          Pointer: ['F12'],
          code: 'F12'
        },
        {
          key: 74,
          Pointer: ['Print'],
          code: 'PrintScreen'
        },
        {
          key: 75,
          Pointer: ['Scroll'],
          code: 'ScrollLock'
        },
        {
          key: 76,
          Pointer: ['Pause'],
          code: 'Pause'
        },
        {
          key: 77,
          Pointer: ['Insert'],
          code: 'Insert'
        },
        {
          key: 78,
          Pointer: ['Home'],
          code: 'Home'
        },
        {
          key: 79,
          Pointer: ['PgUp'],
          code: 'PageUp'
        },
        {
          key: 80,
          Pointer: ['Delete'],
          code: 'Delete'
        },
        {
          key: 81,
          Pointer: ['End'],
          code: 'End'
        },
        {
          key: 82,
          Pointer: ['PgDn'],
          code: 'PageDown'
        },
        {
          key: 83,
          Pointer: ['Right'],
          code: 'ArrowRight'
        },
        {
          key: 84,
          Pointer: ['Left'],
          code: 'ArrowLeft'
        },
        {
          key: 85,
          Pointer: ['Down'],
          code: 'ArrowDown'
        },
        {
          key: 86,
          Pointer: ['Up'],
          code: 'ArrowUp'
        },
        {
          key: 87,
          Pointer: ['NLock'],
          code: 'NumLock'
        },
        {
          key: 88,
          Pointer: ['Num/'],
          code: 'NumpadDivide'
        },
        {
          key: 89,
          Pointer: ['Num*'],
          code: 'NumpadMultiply'
        },
        {
          key: 90,
          Pointer: ['Num-'],
          code: 'NumpadSubtract'
        },
        {
          key: 91,
          Pointer: ['Num+'],
          code: 'NumpadAdd'
        },
        {
          key: 92,
          Pointer: ['NEnt'],
          code: 'NumpadEnter'
        },
        {
          key: 93,
          Pointer: ['Num1'],
          code: 'Numpad1'
        },
        {
          key: 94,
          Pointer: ['Num2'],
          code: 'Numpad2'
        },
        {
          key: 95,
          Pointer: ['Num3'],
          code: 'Numpad3'
        },
        {
          key: 96,
          Pointer: ['Num4'],
          code: 'Numpad4'
        },
        {
          key: 97,
          Pointer: ['Num5'],
          code: 'Numpad5'
        },
        {
          key: 98,
          Pointer: ['Num6'],
          code: 'Numpad6'
        },
        {
          key: 99,
          Pointer: ['Num7'],
          code: 'Numpad7'
        },
        {
          key: 100,
          Pointer: ['Num8'],
          code: 'Numpad8'
        },
        {
          key: 101,
          Pointer: ['Num9'],
          code: 'Numpad9'
        },
        {
          key: 102,
          Pointer: ['Num0'],
          code: 'Numpad0'
        },
        {
          key: 103,
          Pointer: ['Dec'],
          code: 'NumpadDecimal'
        },
        {
          key: 104,
          Pointer: ['App'],
          code: 'App'
        },
        {
          key: 105,
          Pointer: ['K45'],
          code: 'K45'
        },
        {
          key: 106,
          Pointer: ['K56'],
          code: 'K56'
        },
        {
          key: 107,
          Pointer: ['K14'],
          code: 'K14'
        },
        {
          key: 108,
          Pointer: ['K107'],
          code: 'K107'
        },
        {
          key: 109,
          Pointer: ['K131'],
          code: 'K131'
        },
        {
          key: 110,
          Pointer: ['K132'],
          code: 'K132'
        },
        {
          key: 111,
          Pointer: ['K133'],
          code: 'K133'
        },
        {
          key: 112,
          Pointer: ['K150'],
          code: 'K150'
        },
        {
          key: 113,
          Pointer: ['K151'],
          code: 'K151'
        },
        
    ],
    mouse: [
        {
          key: 1,
          Pointer: ['左键'],
          code: 'MS_LEFT'
        },
        {
          key: 2,
          Pointer: ['右键'],
          code: 'MS_RIGHT'
        },
        {
          key: 3,
          Pointer: ['中键'],
          code: 'MS_MIDDLE'
        },
        {
          key: 4,
          Pointer: ['前进'],
          code: 'MS_FORWORD_KEY'
        },
        {
          key: 5,
          Pointer: ['后退'],
          code: 'MS_BACK_KEY'
        },
        {
          key: 6,
          Pointer: ['DPI+'],
          code: 'MS_DPI_UP'
        },
        {
          key: 7,
          Pointer: ['DPI-'],
          code: 'MS_DPI_DOWN'
        },
        {
          key: 8,
          Pointer: ['DPI循环'],
          code: 'MS_DPI_LOOP'
        },
    ],
    media: [
        {
          key: 1,
          Pointer: ['多媒体'],
          code: 'MEDIA'
        },
        {
          key: 2,
          Pointer: ['播放'],
          code: 'PLAY'
        },
        {
          key: 3,
          Pointer: ['停止'],
          code: 'STOP'
        },
        {
          key: 4,
          Pointer: ['上一首'],
          code: 'PREV'
        },
        {
          key: 5,
          Pointer: ['下一首'],
          code: 'NEXT'
        },
        {
          key: 6,
          Pointer: ['音量加'],
          code: 'VOL_ADD'
        },
        {
          key: 7,
          Pointer: ['音量减'],
          code: 'VOL_DEC'
        },
        {
          key: 8,
          Pointer: ['静音'],
          code: 'MUTE'
        },
        {
          key: 9,
          Pointer: ['网页'],
          code: 'WEB'
        },
        {
          key: 10,
          Pointer: ['网页刷新'],
          code: 'WEB_REFRESH'
        },
        {
          key: 11,
          Pointer: ['网页停止'],
          code: 'WEB_STOP'
        },
        {
          key: 12,
          Pointer: ['网页后退'],
          code: 'WEB_BACK'
        },
        {
          key: 13,
          Pointer: ['网页前进'],
          code: 'WEB_FORWARD'
        },
        {
          key: 14,
          Pointer: ['网页收藏'],
          code: 'WEB_FAVORITE'
        },
        {
          key: 15,
          Pointer: ['网页查询'],
          code: 'SEARCH'
        },
        {
          key: 16,
          Pointer: ['我的电脑'],
          code: 'WEB_COMPUTER'
        },
        {
          key: 17,
          Pointer: ['计算器'],
          code: 'CALC'
        },
        {
          key: 18,
          Pointer: ['邮箱'],
          code: 'MAIL'
        },
    ],
    system: [
        {
          key: 1,
          Pointer: ['电源'],
          code: 'POWER'
        },
        {
          key: 2,
          Pointer: ['睡眠'],
          code: 'SLEEP'
        },
        {
          key: 3,
          Pointer: ['唤醒'],
          code: 'WAKE'
        },
    ],
    office: [
        {
            key: 1,
            Pointer: ['打开'],
            code: 'OFFICE_OPEN'
          },
          {
            key: 2,
            Pointer: ['新建'],
            code: 'OFFICE_NEW'
          },
          {
            key: 3,
            Pointer: ['重做'],
            code: 'OFFICE_UNDO'
          },
          {
            key: 4,
            Pointer: ['保存'],
            code: 'OFFICE_SAVE'
          },
          {
            key: 5,
            Pointer: ['复制'],
            code: 'OFFICE_COPY'
          },
          {
            key: 6,
            Pointer: ['剪切'],
            code: 'OFFICE_CUT'
          },
          {
            key: 7,
            Pointer: ['粘贴'],
            code: 'OFFICE_PASTE'
          },
          {
            key: 8,
            Pointer: ['查找'],
            code: 'OFFICE_FIND'
          },
          {
            key: 9,
            Pointer: ['全选'],
            code: 'OFFICE_CHECKED_ALL'
          },
          {
            key: 10,
            Pointer: ['缩小'],
            code: 'OFFICE_DEC'
          },
          {
            key: 11,
            Pointer: ['放大'],
            code: 'OFFICE_ADD'
          },
    ],
    compare: [
         {
          key: 1,
          Pointer: ['LCtrl'],
          code: 'OFFICE_LCTRL'
        },
        {
          key: 2,
          Pointer: ['LShift'],
          code: 'OFFICE_LSHIFT'
        },
        {
          key: 3,
          Pointer: ['LAlt'],
          code: 'OFFICE_LALT'
        },
        {
          key: 4,
          Pointer: ['LWin'],
          code: 'OFFICE_LWIN'
        },
        {
          key: 5,
          Pointer: ['RCtrl'],
          code: 'OFFICE_RCTRL'
        },
        {
          key: 6,
          Pointer: ['RShift'],
          code: 'OFFICE_RSHIFT'
        },
        {
          key: 7,
          Pointer: ['RAlt'],
          code: 'OFFICE_RALT'
        },
        {
          key: 8,
          Pointer: ['RWin'],
          code: 'OFFICE_RWIN'
        },
    ],
    hong: {
      code: 'HONG',
    }
};


 const defaultData = [
    {
        id: 1,
        key:'boardKeys',
        text: '键盘按键',
        component: 'setBoardText',
        btns: keys.keyBoard,
    },
    {
        id: 2,
        key:'mousekeys',
        text: '鼠标按键',
        component: 'setMouse',
        btns: keys.mouse,
    },
    {
        id: 3,
        key:'mediaKeys',
        text: '多媒体按键',
        component: 'setMedia',
        btns: keys.media,
    },
    {
        id: 4,
        key:'systemKeys',
        text: '系统按键',
        component: 'setSystem',
        btns: keys.system,
    },
    {
        id: 5,
        key:'officeKeys',
        text: '办公按键',
        component: 'setOffice',
        btns: keys.office,
    },
    {
        id: 6,
        key:'compareKeys',
        text: '组合按键',
        component: 'setCompare',
        btns: keys.compare,
    },
    {
        id: 7,
        key:'hongKeys',
        text: '宏按键',
        component: 'setHong'
    },
];

export default defaultData;