import React from 'react';
import {Row, Col, Slider} from 'antd'

export const SliderTime = (text:string, data:string) => (
    <>
        <Col span={24}>{`${text}`}</Col>
        <Col span={20}>
            <Slider/>
        </Col>
        <Col span={4}>
        {`${data}s`}
        </Col>
    </>
)

export const OtherSlider = () => (
    <Row>
        <Col span={4}>
            <span>高</span>
        </Col>
        <Col span={16}>
            <Slider/>
        </Col>
        <Col span={4} style={{textAlign: 'right'}}>
            <span>低</span>  
        </Col>
    </Row>
)