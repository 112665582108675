import React from 'react';
import {InputBtns} from '../../../common/component/btns/btns.tsx'

const SetMouse = (props:any) => {
    const changF = () => {
        return props?.data ? [...props?.data].map((item, i) => ({
            key: i,
            text: `${props?.t(`${item.code}`)?props?.t(`${item.code}`):item.Pointer[0]}`,
            value: item.code
        })):[]
    }
    const handClick = (data:any) => {
        if (props?.onClick) props.onClick(data)
    }
    return <div className='key-board'>
       {InputBtns(changF(), (data:any) => handClick && handClick(data))}
    </div>
}

SetMouse.displayName = 'SetMouse';
export default SetMouse;