import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FileAddFilled, UploadOutlined } from '@ant-design/icons'
import {
  addSetting,
  deleteSetting,
  editSetting,
  copySetting,
  // restSetting,
  exportSetting,
  doImportSetting
} from '../../store/setting/imp.ts';
import ItemBtn from '../../common/component/cItem/Index.tsx';
import { readFile } from '../../common/global.ts'
import { Modal } from 'antd';

const option = ['export', 'copy', 'edit','delete', 'rest'];
const Settting = (props:any) => {
  const {settings, checkedProFile} = useSelector((state:any) => state.settingReducer);
  const {connect} = useSelector((state:any) => state.kdevice);
  const dispatch = useDispatch();
  let [showInput, setShowInput] = useState<string>("");

  const handleClick = (type:string, key?:string, value?:string) => {
    if (showInput &&
      type !== 'edit' &&
      type !== 'click') {
      setShowInput("");
      return;
    }
    switch(type) {
      case 'add': dispatch(addSetting());break;
      case 'copy': if (key) dispatch(copySetting(key));break;
      case 'edit': if (key && value) dispatch(editSetting(key, value));break;
      case 'delete':
        if (key && settings && settings?.length > 1) {
          const name = settings.find((item) => item?.key === key)?.name;
          Modal.confirm({
            title: `${props?.t('sureDelete')}${name}?`,
            onOk() {
              dispatch(deleteSetting(key));
              setTimeout(() => {
                if (key === checkedProFile && props?.setChecked) {
                  props?.setChecked(settings[0]?.key);
                }
              }, 200);
            },
            okText: `${props?.t('sure')}`,
            cancelText: `${props?.t('cancel')}`
          });
          
        }
        ;break;
      case 'rest': if (key) {
        // dispatch(restSetting(key));
        if (props.restHandClick) props.restHandClick();
      };break;
      case 'export': if (key) {
        const item = settings.find((ele:any) => ele.key === key);
        exportSetting(JSON.stringify(item));
      };break;
      case 'click': if (key && props?.setChecked && !showInput) props?.setChecked(key) ;break;
    }
  }

  const InputFile = (e:any) => {
    const file = e.target.files[0];
    if (!file) {
        return;
    }
    const reader = new FileReader();
    reader.onload = function(e:any) {
        const content = e?.target?.result;
        const result = readFile(content);
        console.log(content, result);
        if (result) {
          dispatch(doImportSetting(result));
        } else {
          alert('import fail');
        }
       
        // 这里可以处理读取到的文本内容
    };
    reader.onerror = function(e:any) {
        console.error("读取文件时发生错误:", e.target.error);
    };
    reader.readAsText(file, 'UTF-8'); // 默认编码格式为UTF-8，可以根据需要更改
    e.target.value = null;
  }
 
  return (
    <div className='_slider'>
        <div className='_slider_header_text'>
           {`${props.t("slider-name")}`}
        </div>
        { connect ?
          <>
            <div className='_slider_header_svg'>
              <FileAddFilled onClick={() => handleClick('add')} />
              <UploadOutlined onClick={() => document?.getElementById('i-file')?.click()}/>
              <input id='i-file' type='file' accept='text' style={{display:'none'}} onChange={InputFile}/>
            </div>
            <div className='_slider_body'>
              {settings ? settings.map((item:any) => {
                  return ItemBtn(
                    item,
                    (type:string, value?:string)=> {
                      handleClick(type, item?.key, value);
                    },
                    props.t,
                    {
                      showInput,
                      setShowInput
                    },
                    option,
                    checkedProFile === item.key);
                }):null}
            </div>
          </>:null
        }
    </div>
  );
}

Settting.displayName = 'Settting';
export default Settting;