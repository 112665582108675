import React, { useEffect, useState } from 'react';
import { Row, Col, Dropdown, message, Spin } from 'antd';
import Left from '../pages/settting/left.tsx';
import { DisconnectOutlined, LinkOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  install,
  updateKeyRect,
  setTyfun,
  removeDevices, 
  } from '../store/kdevice/imp.ts';
import { setTySetting, installSetting, chooseProfile } from '../store/setting/imp.ts';
import product from '../common/data/product.ts';
import {
  getDevices,
  tySendReport,
  readTyMessage,
  readKeyMessage,
  readDefaultKeyMessage,
  readDeviceMessage,
  saveRectKey,
  checkConnect,
  restAll,
 } from "../pages/agreement.ts";
 import {Poor} from '.././common/component/poor/poor.tsx';

let timefun:any;

// 框架
export default function RootLayout({
  children,
  locale,
  t,
  changeLocale,
}: Readonly<{
  children: any;
  locale: string,
  t: Function,
  changeLocale: Function
}>) {
  const [theme, setTheme] = useState<string>('pink-theme');
  const dispatch = useDispatch();
  const {connect, device, key_rect_size, productName, productId, is32} = useSelector((state:any) => state.kdevice);
  const {settings, checkedProFile} = useSelector((state:any) => state.settingReducer);

  const [hasSleep, setHasSleep] = useState<boolean>(true);
  

  const setinit = async (devices:any) => {
    let connect:boolean = false;

    // 查询设备信息，确认是否连接ok
    const devicemess = await readDeviceMessage();
    // 2.4G设备二次确认是否连接成功
    if (devices.is32) {
      const {data}:any = await checkConnect();
      if (!data?.hasSleep) {
        connect = true;
      }
    } else {
      connect = true;
    }

    if (!connect || !devicemess) {
      message.error(t(`connectfail`));
      return;
    }

    // 读取当前设备的按键信息
    const key_Rect = await readKeyMessage(devicemess?.key_rect_size);

    // 读取当前设备的默认按键信息
    const default_key_Rect = await readDefaultKeyMessage(devicemess?.key_rect_size);

    // 读取当前设备的通用信息
    const tysetting = await readTyMessage();
    
    const d:any ={...devices, ...devicemess, ...tysetting, key_Rect, default_key_Rect, connect};
    //初始化键盘数据

    // 根据产品id判断是否有缓存profile
    let result = JSON.parse(localStorage.getItem(`${d?.productId}`)||'[]');

    if (!result || !result?.length) {
      // 没有profile 根据当前读取的设别信息生成一个
      result = [{
        key: `${new Date().getTime()}${Math.floor(Math.random()*10)}`,
        name: 'profile1',
        tysetting: d?.tysetting,
        hongsetting: ``,
        key_Rect: d?.key_Rect,
        default_key_Rect: d?.default_key_Rect,
        light_rect: d?.light_rect || []
      }];
    }

    // 设置选中首位profile
    dispatch(chooseProfile(result[0].key));

    // store 存储 kdevice
    dispatch(installSetting(d?.productId, {
      hongsetting: ``,
      key_Rect: d?.default_key_Rect,
      default_key_Rect: d?.default_key_Rect,
      tysetting: product.getTysetting(d?.productId),
    }, result));

    // store 存储 kdevice
    dispatch(install({...d}));

    // 如果是2.4g连接开始循环遍历是否休眠
    if (timefun) clearInterval(timefun);
    if (d?.is32) {
      const resp:any = await checkConnect();
      if (resp === "running") return;
      if (resp && resp?.data && resp?.data?.hasSleep === false) setHasSleep(false);
      else setHasSleep(true);
      timefun = setInterval(async () => {
        const resp:any = await checkConnect();
        if (resp === "running") return;
        if (resp && resp?.data && resp?.data?.hasSleep === false) setHasSleep(false);
        else setHasSleep(true);
      }, 3000);
    }
     
  }

  // 设备连接
  const handleClick = async () => {
    try {
       // 使用 dispatch 触发一个 Action
      const devices:any = await getDevices();
      setinit(devices);
    } catch (error) {
      message.error(t(`connectfail`));
    } 
  };

  const setTyInit = (data:string[]) => {
    dispatch(setTyfun(data)); // 存储通用信息到当前使用的通用模块
    dispatch(setTySetting(data)); // 存储通用信息到prodile store
  }
  const setKeyRectInit = (arr:any) => {
    dispatch(updateKeyRect(arr))
  }
  const handClick = async (nkey:string) => {

    const beforItem = checkedProFile ? settings.find(({key}:any) => key === checkedProFile):"";
    const nowItem = settings.find(({key}:any) => key === nkey);

    dispatch(chooseProfile(nkey));

    if (!beforItem || JSON.stringify(beforItem.tysetting) !== JSON.stringify(nowItem.tysetting)
      ) {
      tySendReport(nowItem.tysetting);
      setTyInit(nowItem.tysetting);
    }

    if (!beforItem ||
      JSON.stringify(beforItem.key_Rect) !== JSON.stringify(nowItem.key_Rect)) {
      saveRectKey(nowItem.key_Rect);
      setKeyRectInit(nowItem.key_Rect);
    }

  }
  
  // 重置按钮
  const restHandClick = async () => {
    const result:any = await restAll();

    if (result?.status === 'ok') {
      // 读取当前设备的按键信息
      const key_Rect = await readKeyMessage(key_rect_size);
      setKeyRectInit([...key_Rect]);
      // 读取当前设备的通用信息
      const {tysetting}:any = await readTyMessage();
      setTyInit([...tysetting]);
    }

  }
  const lang:MenuProps['items'] =[
    {
      key: 'en',
      label: (
        <span style={{color: '#333'}} onClick={()=>changeLocale('en')}>
          {t('en')||''}
        </span>
      ),
    },
    {
      key: 'zh',
      label: (
        <span style={{color: '#333'}} onClick={()=>changeLocale('zh')}>
          {t('zh')||''}
        </span>
      ),
    },
    {
      key: 'jp',
      label: (
        <span style={{color: '#333'}} onClick={()=>changeLocale('jp')}>
          {t('jp')||''}
        </span>
      ),
    },
    {
      key: 'ko',
      label: (
        <span style={{color: '#333'}} onClick={()=>changeLocale('ko')}>
          {t('ko')||''}
        </span>
      ),
    }
  ];
  const themes:MenuProps['items'] =[
    {
      key: 'line-theme',
      label: (
        <span style={{color: '#333'}} onClick={()=>setTheme('line-theme')}>
          {t('line-theme')||''}
        </span>
      ),
    },
    {
      key: 'black-theme',
      label: (
        <span style={{color: '#333'}} onClick={()=>setTheme('black-theme')}>
          {t('black-theme')||''}
        </span>
      ),
    },
    {
      key: 'pink-theme',
      label: (
        <span style={{color: '#333'}} onClick={()=>setTheme('pink-theme')}>
          {t('pink-theme')||''}
        </span>
      ),
    }
  ];

  useEffect(():any => { 
    if (localStorage.getItem('key-board-locale-lang') &&
      ['zh', 'en', 'jp', 'ko'].includes(localStorage.getItem('key-board-locale-lang') as string)) {
      changeLocale(localStorage.getItem('key-board-locale-lang')||'en');
    }
    if (localStorage?.getItem('key-board-theme-color') &&
      ['line-theme', 'black-theme', 'pink-theme'].includes(localStorage.getItem('key-board-theme-color') as string))
      setTheme(localStorage.getItem('key-board-theme-color')||'pink-theme');
  }, []);

  useEffect(() => {
    function removedevice(d:any) {
      if (device?.productId === d?.device?.productId) dispatch(removeDevices());
    }
    (navigator as any)?.hid?.removeEventListener("disconnect", removedevice);
    (navigator as any)?.hid?.addEventListener("disconnect", removedevice);

  }, [device]);

  useEffect(() => {
    localStorage.setItem('key-board-locale-lang', locale?locale:"en");
  }, [locale]);
  useEffect(() => {
    localStorage.setItem('key-board-theme-color', theme?theme:'pink-theme');
  }, [theme]);
  
  return (
    <Spin indicator={undefined} tip="" spinning={connect && is32 && hasSleep}>
      <Row className={`${theme}`}>
        <Col className='header'>
          <div className='logo'>
            <img alt='1' src = {`./assert/logo.png`} />
          </div>
          <div className={`menu ${connect ? 'connect': 'disconnect'} `} onClick={handleClick}>
            {connect ?
              <LinkOutlined size={50} width='50vw' />:
              <DisconnectOutlined/>
            }
            <span className='text'>{connect ? productName:t(`pleaseConnect`)}</span>
            {productId && is32 ? <Poor />:null}
          </div>
          
          <div className='setting'>
            <span className='item'>
              <Dropdown 
                menu={{'items':lang}}
                placement="bottom">
                <span style={{color: '#333'}}>
                  {t(locale)||''}
                </span>
              </Dropdown>
            </span>
            <span className='item'>
              <Dropdown
                menu={{'items':themes}}
                placement="bottom">
                <span style={{color: '#333'}}>
                  {t(theme)||''}
                </span>
              </Dropdown>
            </span>
          </div> 
        </Col>
        <Col className='main-box'>
          <div style={{width: '100vw', height: '92vh'}}>
            <div className='silder'>
              <Left t={t}
                setChecked={handClick}
                restHandClick={restHandClick}/>
            </div>
            <div className='content'>
              {
                connect ?
                  children:
                  <div className='connectTip'>
                    <ExclamationCircleOutlined />
                    {t(`connectTip`)}
                  </div>
              }
            </div>
          </div>
        </Col>
      </Row>
    </Spin>
  );
}
                                                                                                                                                                                                                                                                     