const nType = {
    getDatas: 'GETSETTINGS',
    getData: 'GETSETTING',
    install: 'LEFTINSTALL',
    add: 'ADDSETTING',
    delete: 'DELETESETTING',
    export: 'EXPORTSETTING',
    copy: 'COPYSETTING',
    rest: 'RESRSETTING',
    edit: 'EDITSETTING',
    checkedKey: 'CHECKEDKEY',
    setHongName: 'SETHONGNAME',
    checkedProFile: 'CHECKEDPROFILE',
    setTySetting: 'SETTYSETTING',
    changeKeyRect: 'CHANGEKEYRECT',
    Import: 'IMPORTSETTINGS',
}

export default nType;