const nType =  {
    connectDevice: 'CONNECTDEVICE',
    getDeviceMessage: 'GETCONNECTMESSAGE',
    setTyfun: 'SETTYFUN',
    install: 'INSTALL',
    setRectKey: 'SETRECTKEY',
    updateKeyRect: 'UPDATEKEYRECT',
    installLightRect: 'INSTALLLIGHTRECT',
    updateLightRect: 'UPDATELIGHTRECT'
}

export default nType;