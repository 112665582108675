import React, { useState } from 'react';
import { Select, Button, InputNumber, Input, Tooltip } from 'antd';
import './style.less';


type btnmess = {
    icon?: string,
    text: string,
    value: string
}
type SeletType = {
    label?: string,
    value: number,
    icon?: string,
}

type keyBtn = {
    id?: number,
    key?: Array<string>,
    code?: string,
    pointer?: Array<number>,
    keyCode?: number,
    message?: {
        w: number,
        h: number,
        nw?: number,
        nh?: number,
        color?: string
    },
}

export const InputBtn = ({icon, text, value}:btnmess, i:number) => {
    const [val, setVal] = useState<string|null>(text);
    return <Input
        key={i}
        className='input-Btn'
        maxLength={8}
        value={val||""}
        onChange={(dd:any) => setVal(dd?.target?.value||"")}
        />
}
export const keysBtn = ({icon, text, value}:btnmess, i:number, handClick:Function, checked:boolean = false) => {
    return <Button
        key={i}
        className={`input-Btn${checked? " input-Btn-checked":""}`}
        onClick={() => handClick&&handClick(value)}
        >{`${text}`}</Button>
}

export const InputBtns = (arr:Array<btnmess>, handClick:Function, checkedarr:string[] = []) => {
    return <>
    {
        checkedarr?.length ?
            arr.map((item:btnmess, i:number) => keysBtn(item, i, handClick, checkedarr.includes(item?.value))):
            arr.map((item:btnmess, i:number) => keysBtn(item, i, handClick))
    }
    </>
}

const LineBtn = (
    {icon, label, value}:SeletType,
    className?:string,
    t1:Function=()=>{},
    handClick:Function=() =>{}) => {
    return <Button
        key={value}
        className={className}
        icon={icon}
        value={value}
        onClick={() => handClick ? handClick(value):null}>
            {t1(label)}
        </Button>
}

export const LineBtns = (
    arr:Array<SeletType>,
    checked:number,
    t:Function,
    onClick:Function):any => {
    return <>
    {
        arr.map((item:SeletType) =>
            LineBtn(item, checked===item.value ? 'input-Btn-checked':'input-Btn', t, onClick)
        )
    }
    </>
}

//键盘按钮
export const keyButton = (
    item:keyBtn,
    scal:number= 25,
    pressKey?:{
        keyCode: number,
        code: string
    }, 
    keyCodeRelative:any={},
    onClick:Function=()=>{},
    checkedKey:string = '',
    movein:string = '',
    setMovein:Function=()=>{},
    t:Function=()=>{},
    colors:any={}
) => {

    const hascheck = (!item?.code && pressKey?.keyCode === item?.keyCode) || pressKey?.code===item?.code;
    const textcolor = item.code && keyCodeRelative?.[item?.code] && item.code !== keyCodeRelative?.[item?.code] ?"red":"#666";
    const currentText = item?.code && keyCodeRelative?.[item?.code] ?
        (t(keyCodeRelative?.[item?.code])? t(keyCodeRelative?.[item?.code]):keyCodeRelative?.[item?.code]):"";

    const getBorderColor = () => {
        // if (checkedKey === item.code) {
        //     return '1px rgba(52, 52, 52, 0.3) solid';
        // } else 
        if (movein===item?.code) {
            return '1px rgba(52, 52, 52, 0.1) solid';
        } else if (item?.code && colors && colors[item.code]) {
            // console.log(`${item.code}, ${colors[item.code]}`)
            return `1px ${colors[item.code]} solid`
        } else {
            return '1px rgba(54, 54, 54, 0.55) solid';
        }
    }

    const style:any = {
        width: `${(item.message?.w||0) * scal}px`,
        height: `${(item.message?.h||0) * scal}px`,
        position: `absolute`,
        // border: `1px ${item.message?.color || '#333'} solid`,
        border: getBorderColor(),
        backgroundColor: `${checkedKey===item?.code ? 'rgba(52, 52, 52, 0.1)':
            (!hascheck ? 'rgba(255, 255, 255, 0.35)':'rgba(0, 0, 0, 0.1)')} `,
        left: `${(item?.pointer?.[0] || 0) * scal}px`,
        top: `${(item?.pointer?.[1] || 0) * scal}px`,
        borderRadius: '5px',
    }
    const mouseEnter = () => {
        console.log(keyCodeRelative, item?.code, keyCodeRelative?.[item?.code||""], t(keyCodeRelative?.[item?.code||""]))
        if (!movein) setMovein(item?.code);
    }
    const mouseOut = () => {
        if (movein) setMovein("");
    }
    // const keyPress = (e:any) => {
    //     console.log(e, '-------------------e')
    // }
    
    return  <Tooltip placement="topLeft" key={`${item?.code}`}
        title={<span>{`${t(`default`)} ${t(item?.code)}`}<br/>
            {`${t(`current`)} ${currentText}`
            }</span>}>
        <div style={style} className='key-bg'
            key={`${item?.code}`}
            onMouseEnter={mouseEnter}
            onMouseOut={mouseOut}
            onClick={() => {
                onClick && onClick(item?.code);
            }}>
            <div className='key-content' style={{color: textcolor}}> 
                {`${item?.key?.[0]||""} ${item?.key?.[1]||""}`}
            </div>
        </div>
    </Tooltip>
}


export const nSelect = (arr:Array<SeletType>) => {
    return <Select className='select1'>
        {arr&&arr?.length ? arr.map((arr:SeletType, index:number) => (
            <Select.Option value={`${arr.value}`} key={`${arr.value}${index}`}>{`${arr.label}`}</Select.Option>
        )):null} 
    </Select>
}


export const HongBtn = (item:{
    key: string,
    option: string,
    time: number,
}, ikey: number,
    setTime: Function = () => {},
    t:Function = () => {},
) => {

    return <div className={`${item.option==='up'? 'HongBtn-top':'HongBtn-bottom'}`} key={ikey}>
        <div className='HongBtn-content'>
            <div className='flexbox'>
                <div className='HongBtn-top-tag'></div>
                <Button
                    className='input-Btn' 
                >
                    {`${item.key && t(`${item.key}`) ? t(`${item.key}`):item.key}`}
                </Button>
            </div>
            <div className='flexbox'>
                <InputNumber
                    width={100}
                    min={1}
                    max={65535}
                    value={item?.time}
                    placeholder='请输入0-100000内的数字'
                    onChange={(value) => setTime(value)}
                />
            </div>
        </div>
    </div>
}
export const HongBtns = (arr:Array<{
    key: string,
    option: string,
    time: number,
}>,
    setOption:Function = () => {},
    t:Function = () => {},
) => {
    if (!arr || !arr?.length) return null;
    const changeTime = (_time:number, i:number) => {
        setOption(arr.map(
            (item:any, _i:number) => _i===i?{...item, time: _time ? _time:1}: item
        ));
    }
    return <>
    {
        arr&&arr.length ? arr.map((item, i:number) => 
            HongBtn(item, i, (n:number) => changeTime(n, i), t))
        :null
    }
    </>
}