import Type from './type.ts'
const defaultsetting = {
    key: `${new Date().getTime()}`,
    name: 'm',
    option: []
}


const initialState = {
    settings: [],
    name: '',
};

const setLocal = (name:string, settings:any) => {
    console.log('hong story', name, JSON.stringify(settings))
    localStorage.setItem(name, JSON.stringify(settings));
}
  
const hongstore = (state = initialState, action:any) => {
    let arr:any = state.settings;
    switch (action.type) {
        case Type.add:
            arr.push({
                ...defaultsetting,
                key: `${new Date().getTime()}${Math.floor(Math.random()*10)}`,
                name: `m${arr?.length+1}`
            });
            setLocal(state.name, arr);
        return {
            ...state,
            settings:  JSON.parse(JSON.stringify(arr)),
        };
        case Type.Import:
            arr.push({
                ...defaultsetting,
                ...action?.importsetting,
                key: `${new Date().getTime()}${Math.floor(Math.random()*10)}`,
            });
            setLocal(state.name, arr);
            // alert(JSON.stringify(action?.importsetting))
        return {
            ...state,
            settings:  JSON.parse(JSON.stringify(arr)),
        };
        case Type.delete:
            arr = arr.filter((item:any) => action.key !== item.key);
            setLocal(state.name, arr);
        return {
            ...state,
            settings:  JSON.parse(JSON.stringify(arr)),
        };
        case Type.edit:
            arr = arr.map((item:any) => {
                return {
                    ...item,
                    name: action.key === item.key ? action.value : item.name,
                }
            });
            setLocal(state.name, arr); 
        return {
            ...state,
            settings:  JSON.parse(JSON.stringify(arr)),
        };
        case Type.copy:
            const newarr = arr.find((item:any) => action.key === item.key);
            arr.push({
                ...newarr,
                key: `${new Date().getTime()}${Math.floor(Math.random()*10)}`,
                name: `${newarr?.name}_copy`,
                option: JSON.parse(JSON.stringify(newarr && newarr?.option ? newarr?.option:[]))
            });
            setLocal(state.name, arr);
        return {
            ...state,
            settings:  JSON.parse(JSON.stringify(arr)),
        };
        case Type.save:
            // arr = arr.map((item:any) => {
            //     return {
            //         ...item,
            //         option: action.key == item.key ? action.option : item.option,
            //     }
            // })
            setLocal(state.name, action.settings);
        return {
            ...state,
            settings:  JSON.parse(JSON.stringify(action.settings)),
        };
        case Type.getDatas:
            // alert(`action.name ${action.name}`)
        const hongsetting = action.name ? localStorage.getItem(action.name):'[]'; 
        return {
            ...state,
            name: action.name,
            settings:  hongsetting ? JSON.parse(hongsetting):[],
        };
        case Type.addhongname:
            return {
                ...state,
                name: action.name,
            };
        default:
        return state;
    }
};

export default hongstore;